import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import ThetaPunkABI from "../../abis/ThetaPunkABI.json";
import { Finalcontractaddress, RPC_URL } from "../../constants/";
import { getWeb3ContractObject } from "../../utils/";
import { useLocation, useHistory } from "react-router";
import axios from "axios";
import DataLoading from "../../components/Loaders/DataLoading";
import NoDataFound from "../../components/NoDataFound/NoDataFound";

const useStyles = makeStyles((theme) => ({
  NewBreed: {
    background: "#000",
    color: "white",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(18),
    },
  },
  attBox: {
    border: "1px solid #FFB72C",
    padding: 5,
    textAlign: "center",
    borderRadius: 5,
    color: "white",
  },
}));

export default function MarketDetail() {
  const classes = useStyles();
  const history = useHistory();
  const [nftDetails, setnftDetails] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();

  const getDetails = async (id) => {
    setIsLoading(true);
    try {
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress
      );

      const filter = await contract.methods.tokenURI(id.toString()).call();
      const res = await axios.get(filter);

      if (res.status === 200) {
        setnftDetails({ id: id.toString(), nfdData: res.data });
        console.log(" res.data", res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    if (location.search && location.search.slice(1, location.search.length)) {
      let id = location.search.slice(1, location.search.length);
      getDetails(id);
    }
  }, [location]);

  return (
    <>
      {isLoading ? (
        <DataLoading />
      ) : (
        <Box className={classes.NewBreed} maxWidth='md' display='flex'>
          {nftDetails ? (
            <Container>
              <Box mb={5}>
                <Container maxWidth='lg'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box>
                        <Typography variant='h2' style={{ color: "#fafafa" }}>
                          Details
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
              <Grid container spacing={2} style={{ display: "flex" }}>
                <Grid item xs={12} xl={6} md={6} sm={12}>
                  <Box>
                    <img
                      style={{ width: 550, maxWidth: "100%", borderRadius: 10 }}
                      src={nftDetails.nfdData.image}
                      alt=' '
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} xl={6} md={6}>
                  <Box>
                    <Box>
                      <Typography
                        gutterBottom
                        variant='h3'
                        style={{ color: "#fff" }}
                      >
                        {nftDetails.nfdData.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        variant='h4'
                        style={{ color: "#fff" }}
                      >
                        {nftDetails.id}
                      </Typography>

                      <Grid
                        container
                        spacing={1}
                        style={{ display: "flex", marginTop: 20 }}
                      >
                        <Grid item xl={3}>
                          <Typography style={{ color: "#226f6c" }}>
                            External URL :
                            <a
                              href={nftDetails.nfdData.external_url}
                              target='_blank'
                            >
                              {` `}
                              {nftDetails.nfdData.external_url}
                            </a>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>

                    <Typography
                      gutterBottom
                      variant='body1'
                      style={{ marginTop: 30 }}
                    >
                      {nftDetails.nfdData.description}
                    </Typography>
                    <Grid
                      container
                      spacing={1}
                      style={{ display: "flex", marginTop: 20 }}
                    >
                      {nftDetails &&
                        nftDetails.nfdData.attributes.map((data) => {
                          return (
                            <Grid item xl={3} sm={3} md={3}>
                              <Box className={classes.attBox}>
                                <Typography variant='subtitle1'>
                                  {data.trait_type}
                                </Typography>
                                <Typography variant='body2'>
                                  {data.value}
                                </Typography>
                              </Box>
                            </Grid>
                          );
                        })}
                    </Grid>
                    <Box mt={3} textAlign='right'>
                      <Button
                        style={{
                          backgroundColor: "rgb(16 86 84)",
                          margin: 5,
                        }}
                        variant='contained'
                        color='secondary'
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        Back
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          ) : (
            <Box>
              <NoDataFound />
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
