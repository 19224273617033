import React, { useContext, useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  TextField,
} from "@material-ui/core";
import NFTCard from "../../components/NFT/NFTCard";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { Finalcontractaddress } from "../../constants/";
import ThetaPunkABI from "../../abis/ThetaPunkABI.json";
import { getWeb3ContractObject, getWeb3Obj } from "../../utils";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  AboutUs: {
    background: "#000000 !important",
    minHeight: "calc(100vh - 379px)",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(13),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(8),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(10),
    },
    "& .sectionHeading": {
      "& h2": {
        color: "white",
      },
    },
    //'& input ': {
    //  //backgroundColor: 'white',
    //  borderColor: 'white',
    //},
    "& h4": {
      color: "white",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .MuiOutlinedInput-notchedOutline: hover": {
      borderColor: "white",
    },
  },
}));

const dataList = [
  {
    id: "1",
    name: "9",
    nfdData: {
      image: "images/card-bg.png",
      name: "alpha",
    },
  },
  {
    id: "2",
    name: "10",
    nfdData: {
      image: "images/card-bg.png",
      name: "beta",
    },
  },
];

export default function UserSearchNFT() {
  const classes = useStyles();
  const [walletAddress, setWalletAddress] = useState("");
  const [myNFTList, setMyNFTList] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const { account, library } = useWeb3React();
  const [isLoading, setIsLoading] = useState(false);
  const [nftCount, setNftCount] = useState();
  const [isLoadingNFTS, setIsLoadingNFTS] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const Gallery = async (isSearchedUser = isSearched) => {
    setIsSubmit(true);
    if (walletAddress !== "") {
      const web3 = await getWeb3Obj();
      const isAddressValid = await web3.utils.isAddress(walletAddress);
      if (isAddressValid) {
        setIsLoading(true);
        setIsLoadingNFTS(true);
        setMyNFTList([]);

        let gallArr = [];

        const contract = await getWeb3ContractObject(
          ThetaPunkABI,
          Finalcontractaddress
        );
        console.log("contract", contract);
        sessionStorage.setItem("searchWalletAddress", walletAddress);

        const txaCount = await contract.methods.balanceOf(walletAddress).call();
        const txa = txaCount.toString();
        console.log("txa", txa);

        setNftCount(txa);
        if (txa == "0") {
          setMyNFTList([]);
          setIsLoading(false);
        }
        try {
          for (let i = 0; i < txa; i++) {
            const id = await contract.methods
              .tokenOfOwnerByIndex(walletAddress, i)
              .call();
            const filter = await contract.methods
              .tokenURI(id.toString())
              .call();
            const res = await axios.get(filter);

            if (res.status === 200) {
              gallArr.push({ id: id.toString(), nfdData: res.data });

              if (myNFTList.length - 1 <= i) {
                sessionStorage.setItem(
                  `searchArr${walletAddress}`,
                  JSON.stringify(gallArr)
                );
              }

              if (i === 0) {
                setIsLoadingNFTS(false);
              }
            }
          }
          setMyNFTList(gallArr);
          setIsLoading(false);
        } catch (e) {
          setIsLoadingNFTS(false);

          setIsLoading(false);
          console.log(e);
        }
      } else {
        toast.warn("Please enter valid wallet address");
      }
    } else {
      toast.warn("Please enter wallet address");
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem("searchWalletAddress") &&
      sessionStorage.getItem("searchWalletAddress").length > 8
    ) {
      setWalletAddress(sessionStorage.getItem("searchWalletAddress"));
    }
  }, [account]);

  useEffect(() => {
    var refreshIntervalId = setInterval(() => {
      if (
        sessionStorage.getItem("searchWalletAddress") &&
        sessionStorage.getItem("searchWalletAddress").length > 8
      ) {
        if (
          sessionStorage.getItem(
            `searchArr${sessionStorage.getItem("searchWalletAddress")}`
          )
        ) {
          const list = JSON.parse(
            sessionStorage.getItem(
              `searchArr${sessionStorage.getItem("searchWalletAddress")}`
            )
          );
          let keys = ["id"];
          const filtered = list.filter(
            (
              (s) => (o) =>
                ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join("|"))
            )(new Set())
          );
          setMyNFTList(filtered);
        }
      }
    }, 1000);
    return () => clearInterval(refreshIntervalId);
  }, [account]);

  return (
    <>
      <Box className={classes.AboutUs}>
        {" "}
        <Box>
          <Container maxWidth='md'>
            <Grid container spacing={2} className='sectionHeading'>
              <Grid item xs={12}>
                <Box style={{ textAlign: "left", color: "#fafafa" }}>
                  <Typography variant='h2'>Search NFT</Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth='md'>
            <Box mt={0} textAlign='center'>
              <TextField
                onChange={(e) => {
                  setWalletAddress(e.target.value);
                  // setIsSearched(false);
                  // setMyNFTList([]);
                }}
                value={walletAddress}
                variant='outlined'
                // label='Wallet address'
                placeholder='Please enter wallet address'
                fullWidth
                error={isSubmit && walletAddress === ""}
                helperText={
                  isSubmit && walletAddress === "" && "Please Enter Address"
                }
                style={{ margin: "20px 0" }}
              />
              <Button
                variant='contained'
                onClick={() => Gallery(false)}
                color='primary'
              >
                Search
              </Button>
            </Box>
          </Container>
          <Container maxWidth='lg'>
            {" "}
            <Box textAlign='center' mt={2}>
              {nftCount && !isLoadingNFTS && nftCount != 0 && (
                <Typography style={{ color: "#fafafa" }}>
                  {nftCount} records found
                </Typography>
              )}
            </Box>{" "}
            <Box textAlign='center'>
              {isLoading && nftCount != myNFTList.length && (
                <Typography style={{ color: "#fafafa" }}>Loading...</Typography>
              )}
            </Box>
            <Box mt={2} mb={2}>
              <Grid container spacing={2}>
                {myNFTList &&
                  myNFTList.map((data, i) => {
                    return (
                      <Grid item xs={12} md={6} lg={3} key={i}>
                        <NFTCard
                          isChange={false}
                          data={data}
                          index={i}
                          setOpen={(data) => {}}
                        />
                      </Grid>
                    );
                  })}
              </Grid>

              <Box textAlign='center' mt={2}>
                {nftCount && !isLoading && nftCount == 0 && (
                  <Box>
                    <NoDataFound />
                  </Box>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
