import React, { useContext, useState, useEffect } from 'react'
import {
  Box,
  Button,
  makeStyles,
  Typography,
  Container,
  LinearProgress,
  Grid,
  InputBase,
  InputAdornment,
  Modal,
  TextField,
  IconButton,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import IdoDetail from '../../components/IdoDetail'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {} from 'react-feather'
import { UserContext } from '../../context/User'
import { useLocation } from 'react-router'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import Web3 from 'web3'
import PlatformAbi, { Erc20TokenAbi, AvalancheAddress } from '../../context/abi'
import { RPC_URL } from '../../constants'
import { getWeb3ContractObject, getWeb3Obj, getContract } from '../../utils'
import StakingABI from '../../context/StakingABI.json'
import moment from 'moment'
import { ethers, utils } from 'ethers'
import ButtonCircularProgress from '../../components/ButtonCircularProgress'
import { sortAddress } from '../../utils'
import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Label } from '@material-ui/icons'
import ConnectWallet from '../../components/ConnectWalletPopUp/Index'
import erc20TokenAbi from '../../context/erc20TokenAbi.json'
function myFunction() {
  var x = document.getElementById('snackbar')
  x.className = 'show'
  setTimeout(function () {
    x.className = x.className.replace('show', '')
  }, 3000)
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  progressbox: {
    width: '100%',
    maxWidth: '700px',
    margin: '20px auto',
  },

  join: {
    border: '1.5px solid #ccc',
    marginRight: '20px',
    color: '#fff',
    '&:hover': {
      background: 'grey !important',
      color: '#000',
      border: '1.5px solid #000',
    },
    '@media(max-width:767px)': {
      marginRight: '0px',
      marginBottom: '10px',
    },
  },
  table2: {
    display: 'grid',
    fontSize: '16px !important',
    color: '#14bbf5 !important',
  },
  table3: {
    display: 'grid',
    fontSize: '14px !important',
    color: '#000 !important',
  },

  token: {
    backgroundColor: '#14bbf5',
    color: '#fff',
    border: '1.5px solid #14bbf5',
    '&:hover': {
      backgroundColor: '#fff',
      color: ' #14bbf5',
    },
  },
  modalBuy: {
    position: 'absolute',
    top: '23%',
    left: '38%',
    // transform: "translate(-50%, -50%)",
    background: 'linear-gradient( 152.97deg, #fff3 0%, #fff0 100%)',
    backdropFilter: 'blur(42px)',
    // background: "#000",
    color: '#fff',
    width: 'fit-content',
    // padding: "15px",
    borderRadius: '12px',
    border: '2px solid #3e3c3c',
  },
  BoxInner: {
    padding: '25px',
    textAlign: 'center',
    '& h3': {
      fontSize: '16px',
      fontWeight: '500',
      marginBottom: '8px',
    },
    '& h4': {
      fontSize: '14px',
      fontWeight: '400',
      marginBottom: '8px',
    },
  },
  inputField: {
    border: '1px solid #ccc',
    color: '#fff',
    borderRadius: '12px',
    padding: '10px',
    marginBottom: '8px',
  },
  detailsBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h4': {
      fontSize: '14px',
      color: '#fff',
      marginTop: '0px !important',
      lineHeight: '0px !important',
    },
    '& h5': {
      fontSize: '14px',
      color: '#fff',
    },
  },
  modalHeadBody: {
    background: '#000',
    color: '#ccc',
    padding: '25px',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },
}))
export default function Details() {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const auth = useContext(UserContext)
  const { account, library, chainId } = useWeb3React()
  const [isLoadingwith, setIsLoadingwith] = useState(false)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const location = useLocation()
  const [index, setIndex] = React.useState(
    parseInt(
      location.search
        ? location.search.substring(1, location.search.length)
        : false,
    ),
  )
  const [isUpdatingBuy, setIsUpdatingBuy] = useState(false)
  const [isupdating, setIsupdating] = useState(false)
  const [copyText, setCopytext] = useState('')
  const [tokenAmout, setTokenAmout] = useState('')
  const [open, setOpen] = useState(false)
  const [poolDataList, setpoolDataList] = useState([])
  const [poolMoreDataa, setpoolMoreDataList] = useState([])
  const [poolExtraDataa, setpoolExtraData] = useState([])
  const [maxInvest, setmaxInvest] = useState('')
  const [minInvest, setMinInvest] = useState('')
  const [maxUserInvest, setMaxInvestuser] = useState('')
  const [myViewData, setMyViewData] = useState([])
  const [tokenName, setTokenName] = useState('')
  const [leftoverbtnName, setLeftOverBtnName] = useState('Withdraw Leftovers')
  const [isProcessing, setIsprocessing] = useState(false)
  const [buyBtnText, setBuyBtnText] = useState('Buy')
  const [contractTokenName, setContractTokenName] = useState()
  const [isWhitelistLoading, setIsWhitelistLoading] = useState(false)
  const [addressStr, setAddressStr] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isWhitelist, setIsWhitelist] = useState(false)
  const [adminWalletAddress, setAdminWalletAddress] = useState('')

  const [updateStakeOpen, setUpdateStakeOpen] = useState(false)
  const [poolFee, setFee] = useState('')
  const [adminAccumulatedFee, setAdminAccumulatedfee] = useState('')
  const [investMentDataa, setInvestMentOfUser] = useState('')
  const [
    isWithdrwalInvestmentLoading,
    setIsWithdrwalInvestmentLoading,
  ] = useState(false)
  const [withawbtnName, setWithdrawBtnName] = useState('Withdraw investment')
  const [withdrawFee, setWithdrawFee] = useState('Withdraw Fee')
  const [openWalletConnect, setOpenWalletConnect] = useState(false)
  const [isUpdateMinSatke, setIsUpdateMinSatke] = useState(false)
  const [maxInvestERC20, setMaxInvestERC20] = useState('')
  const [minInvestERC20, setMinInvestERC20] = useState('')
  const [investIDs, setInvestIds] = useState([])
  const getPoolDetailsHandler = async (id) => {
    try {
      setIsupdating(true)
      const web3 = await getWeb3Obj()
      const contractObj = await getWeb3ContractObject(
        PlatformAbi,
        AvalancheAddress,
        RPC_URL,
      )
      console.log('contractObj******', contractObj)
      const adminAccount = await contractObj.methods.owner().call()
      setAdminWalletAddress(adminAccount)
      const fee = await contractObj.methods.Fee().call()
      const adminAccumulatedFee = await web3.eth.getBalance(AvalancheAddress)
      setAdminAccumulatedfee(adminAccumulatedFee)
      setFee(fee)
      const poolBaseData = await contractObj.methods.GetPoolBaseData(id).call()
      const poolMoreData = await contractObj.methods.GetPoolMoreData(id).call()
      const poolExtraData = await contractObj.methods
        .GetPoolExtraData(id)
        .call()
      const maxInvests = await contractObj.methods.MaxETHInvest().call()
      const minInvests = await contractObj.methods.MinETHInvest().call()
      const maxInvestERC = await contractObj.methods.MaxERCInvest().call()
      const minInvestERC = await contractObj.methods.MinERCInvest().call()
      const getMyinvestId = await contractObj.methods
        .GetMyInvestmentIds()
        .call({ from: account })

      if (getMyinvestId.length !== 0) {
        let arr = []
        let arrOfId = []
        let yourToken = []
        for (let i = 0; i < getMyinvestId?.length; i++) {
          const getInvestMydata = await contractObj.methods
            .GetInvestmentData(parseInt(getMyinvestId[i]))
            .call()
          if (getInvestMydata[0] == id) {
            arr.push({ ...getInvestMydata, i })
            arrOfId.push(getMyinvestId[i])
          }
        }

        setInvestIds(arrOfId)
        setMyViewData(arr)
      }

      const tokenNameContract = await getWeb3ContractObject(
        Erc20TokenAbi,
        poolBaseData[0],
        RPC_URL,
      )
      const tokenNameFun = await tokenNameContract.methods.name().call()
      setTokenName(tokenNameFun)
      setmaxInvest(maxInvests.toString())
      setMinInvest(minInvests.toString())
      setMinInvestERC20(minInvestERC.toString())
      setMaxInvestERC20(maxInvestERC.toString())
      setpoolDataList(poolBaseData)
      setpoolExtraData(poolExtraData)
      setpoolMoreDataList(poolMoreData)
      setIsupdating(false)
    } catch (err) {
      setIsupdating(false)
      console.log(err)
    }
  }
  const investHandler = async () => {
    try {
      setBuyBtnText('Processing...')
      setIsUpdatingBuy(true)
      if (account) {
        const daiWithSigner = getContract(
          AvalancheAddress,
          PlatformAbi,
          library,
          account,
        )
        const poolStatusCheck = await daiWithSigner.GetPoolStatus(index)

        if (
          poolExtraDataa[1] &&
          poolExtraDataa[1] == '0x0000000000000000000000000000000000000000'
        ) {
          console.log('tokenAmout*****', tokenAmout)
          console.log(
            'minInvestERC20-----',
            ethers.utils.formatEther(minInvest),
          )
          console.log(
            'maxInvestERC20-----',
            ethers.utils.formatEther(maxInvest),
          )
          if (
            tokenAmout >= ethers.utils.formatEther(minInvest) &&
            tokenAmout <= ethers.utils.formatEther(maxInvest.toString())
          ) {
            if (poolStatusCheck === 0 || poolStatusCheck === 1) {
              if (
                poolDataList[3] * tokenAmout <=
                ethers.utils.formatEther(poolMoreDataa[1])
              ) {
                const InvestFun = await daiWithSigner.InvestETH(index, {
                  value: ethers.utils.parseUnits(tokenAmout, 18).toString(),
                })
                await InvestFun.wait()
                setBuyBtnText('Buy')
                setIsUpdatingBuy(false)
                toast.success(
                  'Your transaction has been completed successfully',
                )
                setOpen(false)
                getPoolDetailsHandler()
              } else {
                setBuyBtnText('Buy')
                setIsUpdatingBuy(false)
                toast.error('Not enought tokens in the pool')
              }
            } else {
              toast.error('Wrong pool status to caluculate tokens')
              setBuyBtnText('Buy')
              setIsUpdatingBuy(false)
            }
          } else {
            toast.error('Investment amount is not valid')
            setBuyBtnText('Buy')
            setIsUpdatingBuy(false)
          }
        } else {
          const contractApprove = getContract(
            poolExtraDataa[1],
            erc20TokenAbi,
            library,
            account,
          )
          console.log('contractObj', contractApprove)
          console.log('tokenAmout*****', tokenAmout)
          console.log(
            'minInvestERC20-----',
            ethers.utils.formatEther(minInvestERC20),
          )
          console.log(
            'maxInvestERC20-----',
            ethers.utils.formatEther(maxInvestERC20),
          )
          if (
            parseFloat(tokenAmout) >=
              ethers.utils.formatEther(minInvestERC20.toString()) &&
            parseFloat(tokenAmout) <=
              ethers.utils.formatEther(maxInvestERC20.toString())
          ) {
            if (poolStatusCheck === 0 || poolStatusCheck === 1) {
              if (
                poolDataList[3] * tokenAmout <=
                ethers.utils.formatEther(poolMoreDataa[1])
              ) {
                setBuyBtnText('Approving...')
                const approveFun = await contractApprove.approve(
                  AvalancheAddress,
                  ethers.utils.parseUnits(tokenAmout, 18).toString(),
                )
                await approveFun.wait()
                toast.success('Your transaction has been approved successfully')
                setBuyBtnText('Investing...')
                const InvestFun = await daiWithSigner.InvestERC20(
                  index,
                  ethers.utils.parseUnits(tokenAmout, 18).toString(),
                )
                await InvestFun.wait()
                setBuyBtnText('Buy')
                setIsUpdatingBuy(false)
                toast.success(
                  'Your transaction has been completed successfully',
                )
                setOpen(false)
                getPoolDetailsHandler()
              } else {
                setBuyBtnText('Buy')
                setIsUpdatingBuy(false)
                toast.error('Not enought tokens in the pool')
              }
            } else {
              toast.error('Wrong pool status to caluculate tokens')
              setBuyBtnText('Buy')
              setIsUpdatingBuy(false)
            }
          } else {
            toast.error('Investment amount is not valid')
            setBuyBtnText('Buy')
            setIsUpdatingBuy(false)
          }
        }
      } else {
        toast.warn('Connect your wallet')
        setBuyBtnText('Buy')
        setIsUpdatingBuy(false)
      }
    } catch (err) {
      toast.error(err.message)
      console.log(err)
      setBuyBtnText('Buy')
      setIsUpdatingBuy(false)
    }
  }
  useEffect(() => {
    getPoolDetailsHandler(index)
  }, [account, index])

  useEffect(() => {
    if (account) {
      setOpenWalletConnect(false)
    }
  }, [account])
  const withdrawInvestmentHandler = async () => {
    try {
      if (account) {
        setIsWithdrwalInvestmentLoading(true)
        setWithdrawBtnName('Withdrawing...')
        const contractWithSigner = getContract(
          AvalancheAddress,
          PlatformAbi,
          library,
          account,
        )

        if (myViewData.length !== 0 && investIDs.length !== 0) {
          for (let i = 0; i < myViewData.length; i++) {
            const res = await contractWithSigner.IsReadyWithdrawInvestment(
              investIDs[i],
            )
            if (res) {
              const WithdrawInvestmentRes = await contractWithSigner.WithdrawInvestment(
                investIDs[i],
              )
              await WithdrawInvestmentRes.wait()
              toast.success('Your transaction has been completed successfully')
              getPoolDetailsHandler()
              setIsWithdrwalInvestmentLoading(false)
              setWithdrawBtnName('Withdraw Investment')
            } else {
              toast.error('Can not claim tokens yet!!')
              setIsWithdrwalInvestmentLoading(false)
              setWithdrawBtnName('Withdraw Investment')
            }
          }
        } else {
          toast.error('No investment found!!')
          setIsWithdrwalInvestmentLoading(false)
          setWithdrawBtnName('Withdraw Investment')
        }
      } else {
        toast.warn('Please connect your wallet')
        setIsWithdrwalInvestmentLoading(false)
        setWithdrawBtnName('Withdraw Investment')
      }
    } catch (error) {
      setIsWithdrwalInvestmentLoading(false)
      setWithdrawBtnName('Withdraw Investment')
    }
  }
  const leftOverTokensHandler = async () => {
    try {
      if (account) {
        setIsprocessing(true)
        setLeftOverBtnName('Withdrawing...')

        const contractWithSigner = getContract(
          AvalancheAddress,
          PlatformAbi,
          library,
          account,
        )

        const IsReadyWithdrawLeftOvers = await contractWithSigner.IsReadyWithdrawLeftOvers(
          index,
        )

        if (IsReadyWithdrawLeftOvers) {
          const withdrarLeftOverFun = await contractWithSigner.WithdrawLeftOvers(
            index,
          )

          await withdrarLeftOverFun.wait()
          toast.success('Your transaction has been completed successfully')
          setLeftOverBtnName('Withdraw Leftovers')
          setIsprocessing(false)
        } else {
          setLeftOverBtnName('Withdraw Leftovers')
          toast.error('You cannot withdraw tokens yet')
          setIsprocessing(false)
        }
      } else {
        setLeftOverBtnName('Withdraw Leftovers')
        toast.warn('Please connect your wallet')
        setIsprocessing(false)
      }
    } catch (err) {
      console.log(err)
      setIsprocessing(false)
    }
  }
  const WithdrawFeeHandler = async () => {
    try {
      if (adminAccumulatedFee > '0') {
        setIsLoadingwith(true)
        setWithdrawFee('Withdrawing...')
        const daiWithSigner = getContract(
          AvalancheAddress,
          PlatformAbi,
          library,
          account,
        )

        if (
          poolExtraDataa[1] &&
          poolExtraDataa[1] == '0x0000000000000000000000000000000000000000'
        ) {
          const WithdrawETHFeeRes = await daiWithSigner.WithdrawETHFee(account)
          await WithdrawETHFeeRes.wait()
          toast.success('Your transaction has been completed successfully')
          setWithdrawFee('Withdraw Fee')
        } else if (
          poolExtraDataa[1] &&
          poolExtraDataa[1] != '0x0000000000000000000000000000000000000000'
        ) {
          const WithdrawETHFeeRes = await daiWithSigner.WithdrawERC20Fee(
            poolDataList[0].toString(),
            account,
          )
          await WithdrawETHFeeRes.wait()
          toast.success('Your transaction has been completed successfully')
          setWithdrawFee('Withdraw Fee')
          getPoolDetailsHandler()
        }
        setIsLoadingwith(false)
      } else {
        toast.error('Low fee balance')
      }
    } catch (error) {
      setIsLoadingwith(false)
      setWithdrawFee('Withdraw Fee')
      toast.error(error.message)
    }
  }

  return (
    <Box className="stekesection" mb={5}>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}

      {isupdating ? (
        <Box
          display="flex"
          justifyContent="flex-start"
          padding="10px"
          style={{ minHeight: '100vh' }}
        >
          <Typography style={{ color: '#fff' }}>Loading...</Typography>
          <ButtonCircularProgress />
        </Box>
      ) : (
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box className="text-left projectDetails">
                <Typography variant="h4" style={{ color: '#fff' }}>
                  {tokenName}
                </Typography>
                <Box className="tokenAdd">
                  <Box
                    style={{
                      cursor: 'pointer',
                      color: '#fff',
                      border: '1px solid #fff',
                      width: 'fit-content',
                      padding: '5px 10px',
                      borderRadius: '20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      onClick={() => {
                        window.open(
                          `https://testnet.snowtrace.io/token/${poolDataList[0]}`,
                        )
                      }}
                    >
                      {poolDataList[0]
                        ? sortAddress(poolDataList[0].toString())
                        : '0'}
                    </Typography>
                    <Box>
                      <CopyToClipboard text={poolDataList[0]}>
                        <Button style={{ color: '#fff' }}>COPY</Button>
                      </CopyToClipboard>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {/* <Grid item xs={12} sm={6} lg={6}> */}
              <Box
                className="text-center saleDetails"
                style={{ marginTop: '20px' }}
              >
                <Box className={classes.detailsBox}>
                  <Typography variant="h4">Left overs:</Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5">
                      {poolMoreDataa[1]
                        ? ethers.utils.formatEther(poolMoreDataa[1]?.toString())
                        : '0'}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  className={classes.detailsBox}
                  style={{ marginTop: '10px' }}
                >
                  <Typography variant="h4">Fee:</Typography>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5">
                      {poolFee ? Number(poolFee) / 100 : '0'} %
                    </Typography>
                  </Box>
                </Box>
                {account &&
                  adminWalletAddress &&
                  adminWalletAddress.toLowerCase() ===
                    account.toLowerCase() && (
                    <Box
                      className={classes.detailsBox}
                      style={{ marginTop: '10px' }}
                    >
                      <Typography variant="h4">Accumulated Fee:</Typography>
                      <Box display="flex" alignItems="center">
                        <Typography variant="h5">
                          {adminAccumulatedFee
                            ? ethers.utils
                                .formatEther(adminAccumulatedFee)
                                .toString()
                            : '0'}
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
              <Box
                className="text-center saleDetails"
                style={{ marginTop: '20px' }}
              >
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    {poolDataList[1] &&
                      account &&
                      poolDataList[1].toLowerCase() ===
                        account.toLowerCase() && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.join}
                          onClick={leftOverTokensHandler}
                        >
                          {leftoverbtnName}{' '}
                          {isProcessing && <ButtonCircularProgress />}
                        </Button>
                      )}
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    {account &&
                      adminWalletAddress &&
                      adminWalletAddress.toLowerCase() ===
                        account.toLowerCase() && (
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.join}
                          onClick={WithdrawFeeHandler}
                          disabled={
                            isLoadingwith ||
                            isProcessing ||
                            isWithdrwalInvestmentLoading
                          }
                        >
                          {withdrawFee}{' '}
                          {isLoadingwith && <ButtonCircularProgress />}
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box mt={5} className="text-center saleDetails">
                <Typography variant="h4">
                  {/* {poolMoreDetails
                  ? indexData?.presaleCancelled
                    ? "Cancelled"
                    : indexData?.openTime * 1000 < +new Date() &&
                      indexData?.closeTime * 1000 > +new Date()
                    ? "Open"
                    : indexData?.openTime * 1000 > +new Date()
                    ? "Not Opened"
                    : indexData?.uniLiquidityAdded
                    ? "Locked and Listed"
                    : indexData?.closeTime * 1000 < +new Date() &&
                      indexData?.softCapInWei > indexData?.totalCollectedWei
                    ? "Failed"
                    : "Closed"
                  : null} */}
                </Typography>
                <Typography variant="body2" style={{ color: '#fff' }}>
                  {tokenName}
                </Typography>
                <Box className={classes.progressbox}>
                  <LinearProgress
                    variant="determinate"
                    style={{ color: '#14bbf5' }}
                    value={
                      poolDataList[5]
                        ? ethers.utils.formatEther(
                            poolDataList[5]?.toString(),
                          ) -
                          ethers.utils.formatEther(poolMoreDataa[1]?.toString())
                        : '0'
                    }
                  />
                  <Box className="progressData">
                    <Typography variant="body">
                      {poolDataList[5]
                        ? Number(
                            ethers.utils.formatEther(poolDataList[5]) -
                              ethers.utils.formatEther(poolMoreDataa[1]),
                          ).toFixed(4)
                        : '0'}
                      %
                    </Typography>
                    <Typography variant="body">100%</Typography>
                  </Box>
                </Box>
                <Box className="s" mt={4} style={{ position: 'realtive' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.join}
                    fullWidth
                    onClick={() => {
                      if (account) {
                        setOpen(true)
                      } else {
                        setOpenWalletConnect(true)
                      }
                    }}
                  >
                    {account ? `+join` : 'Connect Wallet'}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    className={classes.join}
                    fullWidth
                    style={{ marginTop: '12px' }}
                    onClick={() => {
                      withdrawInvestmentHandler()
                    }}
                    disabled={isWithdrwalInvestmentLoading}
                  >
                    {withawbtnName}
                    {isWithdrwalInvestmentLoading && <ButtonCircularProgress />}
                  </Button>

                  <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    disableEscapeKeyDown={isUpdatingBuy}
                    disableScrollLock={true}
                  >
                    <Box
                      className={classes.modalBuy}
                      style={{ minWidth: '300px' }}
                    >
                      <Box
                        style={{
                          background: '#000',
                          color: '#fff',
                          padding: '25px',
                          borderTopLeftRadius: '12px',
                          borderTopRightRadius: '12px',
                        }}
                      >
                        <Typography variant="h2">Buy Token</Typography>
                      </Box>

                      <Box className={classes.BoxInner}>
                        <Typography variant="h3">
                          Price: 1AVAX ={' '}
                          {poolDataList[3] ? poolDataList[3] : '0'}
                          &nbsp;Token
                        </Typography>
                        {poolExtraDataa[1] &&
                        poolExtraDataa[1] ==
                          '0x0000000000000000000000000000000000000000' ? (
                          <Typography variant="h4">
                            Minimum:&nbsp;
                            {minInvest
                              ? Number(
                                  ethers.utils.formatEther(minInvest),
                                ).toFixed(4)
                              : '0'}
                            &nbsp; {contractTokenName && contractTokenName.name}{' '}
                            |&nbsp; Maximum:&nbsp;
                            {maxInvest
                              ? Number(
                                  ethers.utils.formatEther(maxInvest),
                                ).toFixed(1)
                              : '0'}
                          </Typography>
                        ) : (
                          <Typography variant="h4">
                            Minimum:&nbsp;
                            {minInvestERC20
                              ? Number(
                                  ethers.utils.formatEther(minInvestERC20),
                                ).toFixed(4)
                              : '0'}
                            &nbsp; {contractTokenName && contractTokenName.name}{' '}
                            |&nbsp; Maximum:&nbsp;
                            {maxInvestERC20
                              ? Number(
                                  ethers.utils.formatEther(maxInvestERC20),
                                ).toFixed(1)
                              : '0'}
                          </Typography>
                        )}

                        <Box className={classes.inputField}>
                          <InputBase
                            type="number"
                            value={tokenAmout}
                            onChange={(e) => setTokenAmout(e.target.value)}
                          />
                        </Box>

                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className={classes.join}
                          disabled={tokenAmout === '' || isUpdatingBuy}
                          onClick={investHandler}
                        >
                          {buyBtnText}
                          {isUpdatingBuy && <ButtonCircularProgress />}
                        </Button>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Box mt={5} className="text-center saleDetails tableTab">
            <Box className="tabbedButton">
              <AppBar position="static" elevation={0}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="IDO Details" {...a11yProps(0)} />
                </Tabs>
              </AppBar>
            </Box>
            <TabPanel value={value} index={0}>
              <IdoDetail
                poolDataList={poolDataList}
                poolMoreDataa={poolMoreDataa}
                poolExtraDataa={poolExtraDataa}
                maxInvest={maxInvest}
                minInvest={minInvest}
                isupdating={isupdating}
                // maxUserInvest={maxUserInvest}
                myViewData={myViewData}
                tokenName={tokenName}
              />
            </TabPanel>
          </Box>
        </Container>
      )}

      {/* WHITELIST MODAL */}
      {isOpenModal && (
        <Dialog
          open={isOpenModal}
          onClose={() => {
            setAddressStr()
            setIsOpenModal(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
          disableBackdropClick={isWhitelistLoading}
          disableEscapeKeyDown={isWhitelistLoading}
        >
          <DialogContent>
            <Box
              style={{
                background: '#14bbf5',
                color: '#fff',
                padding: '25px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
              }}
            >
              {' '}
              <Typography variant="h6" style={{ color: '#fff' }}>
                {isWhitelist ? 'Add To Whitelist' : 'Remove From Whitelist'}
              </Typography>
            </Box>
            <Box p={3}>
              {/* <label style={{ color: "#fff" }}>User Wallet ID</label> */}
              <TextField
                id="outlined-basic"
                // variant="outlined"
                placeholder="Please enter comma separated address"
                fullWidth
                onChange={(e) => setAddressStr(e.target.value)}
                multiline
                rows={2}
                rowsMax={4}
                inputProps={{}}
                disabled={isWhitelistLoading}
              />
            </Box>
            <DialogActions>
              <Button
                onClick={() => {
                  setIsOpenModal(false)
                  setAddressStr()
                }}
                color="primary"
                disabled={isWhitelistLoading}
              >
                Cancel
              </Button>
              <Button
                disabled={isWhitelistLoading}
                color="primary"
                style={{ color: '#e21ae7' }}
                autoFocus
                // onClick={() => {
                //   if (isWhitelist) {
                //     addToWhitelistHandler();
                //   }
                // }}
              >
                Submit {isWhitelistLoading && <ButtonCircularProgress />}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}

      {/* UPDATE MODAL */}
      {updateStakeOpen && (
        <Dialog
          open={updateStakeOpen}
          onClose={() => {
            setUpdateStakeOpen(false)
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="sm"
          disableBackdropClick={isUpdateMinSatke}
          disableEscapeKeyDown={isUpdateMinSatke}
        >
          <DialogContent>
            <Box
              style={{
                background: '#14bbf5',
                color: '#fff',
                padding: '25px',
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
              }}
            >
              {' '}
              <Typography variant="h6" style={{ color: '#fff' }}>
                Update
              </Typography>
            </Box>

            <DialogActions>
              <Button
                disabled={isUpdateMinSatke}
                onClick={() => {
                  setUpdateStakeOpen(false)
                }}
                color="primary"
              >
                Cancel
              </Button>
              <Button
                disabled={isUpdateMinSatke}
                color="primary"
                style={{ color: '#e21ae7' }}
                autoFocus
                // onClick={updateMinSatekeHandler}
              >
                Submit {isUpdateMinSatke && <ButtonCircularProgress />}
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </Box>
  )
}
