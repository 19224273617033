import React, { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  Box,
  Button,
  Grid,
  FormControl,
  IconButton,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTheme } from "@material-ui/styles";
import { GrFormAdd } from "react-icons/gr";
import { BiMinus } from "react-icons/bi";
import ButtonCircularProgress from "../../components/Loaders/ButtonCircularProgress";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";
import { getContract, getWeb3ContractObject, getWeb3Obj } from "../../utils";
import { ACTIVE_NETWORK, Finalcontractaddress } from "../../constants";
import ThetaPunkABI from "../../abis/ThetaPunkABI.json";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  baanerBox: {
    padding: "80px 0px",
    "& label": {
      fontSize: "18px",
      color: "#FFFFFF",
      marginBottom: "10px",
    },
  },
  quantity: {
    display: "flex",
    "& button": {
      width: "50px",
      height: "50px",
      background: "#222222",
      border: "none",
      color: "#868383",
      fontSize: "30px",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
    },
    "& input": {
      width: "50px",
      height: "50px",
      background: "#000",
      border: "1px solid #595959 !important",
      color: "#868383",
      fontSize: "20px",
      margin: "0 10px",
      textAlign: "center",
      "&:focus-visible": {
        outline: "none",
      },
    },
  },
  imagesBox: {
    position: "relative",
  },
}));
export default function Dashboard(props) {
  var classes = useStyles();
  const user = useContext(UserContext);
  const { account, library, chainId } = useWeb3React();
  const [numberofnft, setNumberofnft] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);

  const mintNFT = async () => {
    if (account && numberofnft && numberofnft !== "") {
      setIsLoading(true);

      try {
        const web3 = await getWeb3Obj();
        var balance = web3.eth.getBalance(account);
        var walletBalance = web3.utils.fromWei((await balance).toString());
        if (parseFloat(walletBalance) > parseFloat(user.nftprice)) {
          const contract = getContract(
            Finalcontractaddress,
            ThetaPunkABI,
            library,
            account
          );

          const checkIsActive = await contract.saleIsActive();
          console.log("checkIsActive", checkIsActive);
          console.log("contract", contract);

          if (checkIsActive) {
            const tx = await contract.mintPUNK({
              value: web3.utils
                .toWei((numberofnft * user.nftprice).toString())
                .toString(),
              gasLimit: 3000000,
              from: account,
            });
            console.log(tx);
            await tx.wait();
            toast.success("Successfully minted");
            setIsLoading(false);
            user.getGalleryData();
            user.getMoreData();
          } else {
            setIsLoading(false);
            toast.warn("Sale is not active");
          }
        } else {
          toast.warn("Insufficient funds");
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log("ERRROR", error);
        toast.error(error.message);
      }
    }
  };
  return (
    <Box className={classes.baanerBox} >
      <Grid container spacing={2} alignItems='center'>
        <Grid item xs={12} sm={6} md={5} lg={5}>
          <Box mb={3}>
            <Typography
              style={{ color: "#fff", fontWeight: "bold" }}
              variant='h2'
            >
              Mint NFT
            </Typography>
          </Box>
          <Box mb={2}>
            <label>Price</label>
            <FormControl
              fullWidth
              className={clsx(classes.margin, classes.textField)}
              variant='outlined'
            >
              <OutlinedInput
                placeholder={user.nftprice}
                id='outlined-adornment-weight'
                endAdornment={
                  <InputAdornment position='end'>AVAX</InputAdornment>
                }
              />
            </FormControl>
          </Box>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={account ? mintNFT : user.connectWallet}
            disabled={isLoading && chainId !== ACTIVE_NETWORK}
            fullWidth
          >
            {account ? "Mint" : "Connect"}{" "}
            {isLoading && <ButtonCircularProgress />}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box className={classes.imagesBox}>
            <img
              src='../images/mint/mint-img-full.png'
              style={{ width: "100%" }}
            />
            {/* <img src='../images/mint-main.png' style={{ width: "100%" }} />
                        <img src='../images/mint/1.png' className="image1" />
                        <img src='../images/mint/2.png' className="image2" />
                        <img src='../images/mint/3.png' className="image3" />
                        <img src='../images/mint/4.png' className="image4" />
                        <img src='../images/mint/5.png' className="image5" />
                        <img src='../images/mint/6.png' className="image6" />
                        <img src='../images/mint/7.png' className="image7" />
                        <img src='../images/mint/8.png' className="image8" />
                        <img src='../images/mint/9.png' className="image9" />
                        <img src='../images/mint/10.png' className="image10" /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
