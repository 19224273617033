export const NetworkContextName = "Avalanche Test";
export const ACTIVE_NETWORK = 43113;
export const Finalcontractaddress =
  "0x663788a7B39Ad13fcA8F3AaB8f7ab5E2d57f106A";
export const RPC_URL = "https://api.avax-test.network/ext/bc/C/rpc";
export const PlatformContractAddressETH =
  "0xEa28eDb09157A89Fe8Bc58460F0fe4E1F0115968";
export const PlatformContractAddressBNB =
  "0x01937c2BAa3A8F07A484626DDF66cd41d23EfA19";

export const getNetworkDetails = (chianId) => {
  switch (chianId.toString()) {
    case "43113":
      return [
        {
          chainId: "0x2A",
          chainName: "Avalanche Test",
          nativeCurrency: {
            name: "Avalanche Test",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: [
            "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
          ],
          blockExplorerUrls: ["https://kovan.etherscan.io"],
        },
      ];
    case "97":
      return [
        {
          chainId: "0x61",
          chainName: "Smart Chain - Testnet",
          nativeCurrency: {
            name: "Smart Chain - Testnet",
            symbol: "BNB",
            decimals: 18,
          },
          rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
          blockExplorerUrls: ["https://testnet.bscscan.com"],
        },
      ];
  }
};

export const NetworkDetails = [
  {
    chainId: "0xA869",
    chainName: "Avalanche FUJI C-Chain",
    nativeCurrency: {
      name: "AVAX",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
  },
];

export const networkList = [
  {
    name: "Binance",
    chainId: "97",
    description: "",
    symbol: "BNB",
  },
  {
    name: "Ethereum",
    chainId: "43113",
    description: "",
    symbol: "ETH",
  },
];

export const getCurrentContractAddress = (chianId) => {
  switch (chianId.toString()) {
    case "43113":
      return PlatformContractAddressETH;
    case "97":
      return PlatformContractAddressBNB;
    default:
      return PlatformContractAddressBNB;
  }
};

export const getToTokenList = (chianId) => {
  switch (chianId.toString()) {
    case "43113":
      return [
        {
          name: "ETH",
          address: "0x0000000000000000000000000000000000000000",
        },
        {
          name: "USDT",
          address: "0x3b5983021E2b18006E1e156EC4751BDAfdE36db8",
        },
      ];

    case "97":
      return [
        {
          name: "BNB",
          address: "0x0000000000000000000000000000000000000000",
        },
        {
          name: "BUSD",
          address: "0x21783C0Ce32e1859F6bccC6e575Ae6019765e443",
        },
      ];
  }
};
