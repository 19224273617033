import React, { useState, useContext } from "react";
import CloseIcon from "@material-ui/icons/Close";
import {
  AppBar,
  Container,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Box,
  Slide,
  Dialog,
  Typography,
  TextField,
  Grid,
} from "@material-ui/core";
import PlatformAbi, { PlatformContractAddress } from "../../context/abi";
// import { AuthContext } from "src/context/Auth";
import { ethers } from "ethers";
// import ButtonCircularProgress from "src/components/ButtonCircularProgress";
import { useWeb3React } from "@web3-react/core";
import { getContract } from "../../utils";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";
import { AuthContext } from "../../context/Auth";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  paper: {
    backgroundImage: "url(images/pool.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    backgroundSize: "contain",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CreatePool({ close, open }) {
  const auth = useContext(AuthContext);
  const { account, library, chainId } = useWeb3React();
  const classes = useStyles();
  const [addressList, setAddressList] = useState("");
  const [isSubmit, setisSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const whiteListHandler = async () => {
    setisSubmit(true);
    if (addressList.length > 0) {
      setisSubmit(false);
      var addressListArr = addressList.split(",");
      setIsLoading(true);
      const daiWithSigner = getContract(
        PlatformContractAddress,
        PlatformAbi,
        library,
        account
      );
      let tx = await daiWithSigner.addwhitelistedAddresses(addressListArr); //amountof token
      await tx.wait();
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      classes={{
        paper: classes.paper,
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h3">Add whitelisted address</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ backgroundColor: "#ffffff" }}>
        <Box mt={15}>
          <Grid container>
            <Grid item xs={12}>
              <Typography>Add whitelisted address</Typography>

              <Typography variant="body2">
                Please enter comma separated values
              </Typography>

              <TextField
                label="Enter Addresses"
                margin="normal"
                variant="outlined"
                multiline
                rows={3}
                fullWidth
                value={addressList}
                onChange={(e) => setAddressList(e.target.value)}
                error={isSubmit && addressList === ""}
                helperText={
                  isSubmit && addressList === "" && "Please Enter Addresses"
                }
              />
            </Grid>
            <Box style={{ width: "100%", textAlign: "right" }} mt={3} mb={3}>
              <Button
                disabled={isLoading}
                variant="contained"
                onClick={whiteListHandler}
              >
                Submit {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
}
