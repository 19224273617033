import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  ListItem,
  List,
  ListItemIcon,
  makeStyles,
  ListItemText,
  Box,
  Tooltip,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { withRouter, Link } from "react-router-dom";
import classNames from "classnames";
import { GiToken } from "react-icons/gi";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import { BsFillHandbagFill } from "react-icons/bs";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { SiEquinixmetal } from "react-icons/si";
import { AiFillRocket } from "react-icons/ai";
import { FiTrendingUp } from "react-icons/fi";
import { MdDashboard } from "react-icons/md";
import { BiNetworkChart } from "react-icons/bi";
import { GiThreeLeaves } from "react-icons/gi";
import { FaGamepad } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";

// import useStyles from "./styles";

// components
const structure = [
  {
    id: 0,
    label: "Mint",
    link: "#",
    icon: <MdDashboard />,
    menutitle: "Mint",
  },
  {
    id: 1,
    label: "Market Place",
    link: "/app/dashboard",
    icon: <BsFillHandbagFill />,
    menutitle: "Market Place",
  },

  {
    id: 2,
    label: "Launchpad",
    link: "#",
    icon: <AiFillRocket />,
    menutitle: "Launchpad",
  },
  {
    id: 3,
    label: "Dex",
    link: "#",
    icon: <FiTrendingUp />,
    menutitle: "Dex",
  },
  {
    id: 4,
    label: "Farm",
    link: "#",
    icon: <GiThreeLeaves />,
    menutitle: "Farm",
  },

  {
    id: 5,
    label: "Affiliate",
    link: "#",
    icon: <BiNetworkChart />,
    menutitle: "Affiliate",
  },
  {
    id: 6,
    label: "Metaverse",
    link: "#",
    icon: <SiEquinixmetal />,
    menutitle: "Metaverse",
    comingsoon: "images/comingSoon.png",
  },
  {
    id: 7,
    label: "Games",
    link: "#",
    icon: <FaGamepad />,
    menutitle: "Games",
    comingsoon: "images/comingSoon.png",
  },
];
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    paddingLeft: "10px",
    height: "100vh",
    position: "fixed",
    borderRight: "0",
    background: "#1f1f1f",
    backdropFilter: "blur(42px)",
    borderRadius: "0px 30px 0px 0px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&::-webkit-scrollbar": {
      width: " 3px",
    },
    "&::-webkit-scrollbar-track ": {
      background: " #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: " #888",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },
  },
  drawerClose: {
    borderRight: "0",
    background: "#1f1f1f",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "60px",
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
    "&::-webkit-scrollbar": {
      width: " 3px",
    },
    "&::-webkit-scrollbar-track ": {
      background: " #f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      background: " #888",
    },
    "&::-webkit-scrollbar-thumb:hover ": {
      background: "#555",
    },
  },
  toolbar: {
    ...theme.mixins.toolbar,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    marginTop: theme.spacing(2),
    marginLeft: 8,
    display: " flex",
    alignItems: "center",
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    // [theme.breakpoints.up("md")]: {
    //   display: "none",
    // },
    "& button": {
      color: "#2598f7",
      marginLeft: "-7px",
    },
  },
  link: {
    textDecoration: "none",
    "&:hover, &:focus": {
      // backgroundColor: "#f8b900",
      background: " linear-gradient( 180deg, #222222 0%, #222222 100%)",
    },
  },
  externalLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textDecoration: "none",
  },
  // linkActive: {
  //   background:" linear-gradient( 180deg, #F8B900 0%, #8F5409 100%)",
  // },
  linkNested: {
    paddingLeft: 0,
    "&:hover, &:focus": {
      backgroundColor: "#f8b900",
    },
  },
  linkIcon: {
    marginRight: "25px",
    color: "#fff",
    transition: theme.transitions.create("color"),
    width: 24,
    display: "flex",
    fontSize: "20px",
    justifyContent: "center",
    background: "#373737",
    borderRadius: "5px",
    padding: "7px",
    minWidth: "35px",
  },
  linkIconActive: {
    color: "#fff",
  },
  linkText: {
    padding: 0,
    color: "#9F9F9F",
    transition: theme.transitions.create(["opacity", "color"]),
    fontSize: 14,
  },
  linkTextActive: {
    color: "#43E3E0",
  },
  linkTextHidden: {
    opacity: 0,
  },
  nestedList: {
    paddingLeft: theme.spacing(2) + 30,
  },
  sectionTitle: {
    marginLeft: theme.spacing(4.5),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    height: 1,
    backgroundColor: "#D8D8D880",
  },
  tokenBox: {
    position: "absolute",
    bottom: 0,
    left: 0,
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: " 10px 20px 10px",
    alignItems: "center",
    borderTop: "1.5px solid #373636",
    "& div": {
      display: "flex",
      width: "50%",
      alignItems: "center",
      fontSize: "14px",
      fontWeight: 600,
      color: "#fff",
      "& .avxtoken": {
        marginRight: "10px",
        width: "30px",
        height: "30px",
        overflow: "hidden",
        borderRadius: "50%",
        "& img": {
          width: "100%",
        },
      },
      "& .dadytoken": {
        width: "30px",
        height: "30px",
        marginRight: "10px",
        overflow: "hidden",
        borderRadius: "50%",
        backgroundColor: "#2f3636",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
          width: "15px",
        },
      },
    },
  },
}));
function Sidebar({ location }) {
  const classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();

  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={!isSidebarOpened}
    >
      {isSidebarOpened ? (
        <Box className={classes.tokenBox}>
          <Box>
            <div className="dadytoken">
              <img src="images/fav.png" />
            </div>
            0.0$
          </Box>
          <Box>
            <div className="avxtoken">
              <img src="images/avx.png" />
            </div>
            0.0$
          </Box>
        </Box>
      ) : (
        <Box className={`${classes.tokenBox} menuclose`}>
          <Box>
            <div className="dadytoken">
              <img src="images/fav.png" />
            </div>
            0.0$
          </Box>
          <Box>
            <div className="avxtoken">
              <img src="images/avx.png" />
            </div>
            0.0$
          </Box>
        </Box>
      )}

      {/* <div className={classes.toolbar} /> */}
      <Box className={classes.mobileBackButton} mb={3}>
        {isSidebarOpened ? (
          <Link to="/app/dashboard">
            <img src="images/logo.svg" style={{ maxWidth: "150px" }} />
          </Link>
        ) : (
          <img src="images/fav.png" style={{ maxWidth: "24px" }} alt="logo" />
        )}

        <IconButton
          className="arrowButton"
          onClick={() => toggleSidebar(layoutDispatch)}
          size="small"
        >
          {isSidebarOpened ? (
            <FiArrowLeft
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <FiArrowRight
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
      </Box>

      {isSidebarOpened ? (
        <List className={classes.sidebarList}>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>

            <a href="https://mint.dexdaddy.com/#/app/dashboard" target="_blank">
              <ListItemIcon className="listicon">
                <MdDashboard />
              </ListItemIcon>
              <ListItemText className="listtext" primary="Mint" />
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a
              href="https://marketplace.dexdaddy.com/#/app/dashboard"
              target="_blank"
            >
              <ListItemIcon className="listicon">
                <BsFillHandbagFill />
              </ListItemIcon>
              <ListItemText className="listtext " primary="Marketplace" />
            </a>
          </ListItem>
          <ListItem className="ActiveLink menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>

            <a href="/">
              <ListItemIcon className="listicon">
                <AiFillRocket />
              </ListItemIcon>
              <ListItemText
                className="listtext activelisttext"
                primary="Launchpad"
              />
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a href="https://dex.dexdaddy.com/#/app/dashboard" target="_blank">
              <ListItemIcon className="listicon">
                <FiTrendingUp />
              </ListItemIcon>
              <ListItemText className="listtext" primary="Dex" />
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a href="https://farm.dexdaddy.com/#/app/dashboard" target="_blank">
              <ListItemIcon className="listicon">
                <GiThreeLeaves />
              </ListItemIcon>
              <ListItemText className="listtext" primary="Farm" />
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a href="https://sale.dexdaddy.com/#/app/dashboard" target="_blank">
              <ListItemIcon className="listicon">
                <BiNetworkChart />
              </ListItemIcon>
              <ListItemText className="listtext" primary="Sale Events" />
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a href="#">
              <ListItemIcon className="listicon">
                <FaHandHoldingUsd />
              </ListItemIcon>
              <ListItemText className="listtext " primary="Stake" />
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a href="#" target="_blank">
              <ListItemIcon className="listicon">
                <SiEquinixmetal />
              </ListItemIcon>
              <ListItemText className="listtext" primary="Metaverse" />
              {isSidebarOpened ? (
                <span className="comingsoonimg">Coming Soon!</span>
              ) : (
                <span className="comingsoonimgclose">Coming Soon!</span>
              )}
            </a>
          </ListItem>
          <ListItem className=" menuLink LinkMenu" disableRipple>
            <span className="shape1"></span>
            <span className="shape2"></span>
            <a href="#" target="_blank">
              <ListItemIcon className="listicon">
                <FaGamepad />
              </ListItemIcon>
              <ListItemText className="listtext" primary="Games" />
              {isSidebarOpened ? (
                <span className="comingsoonimg">Coming Soon!</span>
              ) : (
                <span className="comingsoonimgclose">Coming Soon!</span>
              )}
            </a>
          </ListItem>
        </List>
      ) : (
        <List className={classes.sidebarList}>
          <Tooltip title="Mint" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a
                href="https://mint.dexdaddy.com/#/app/dashboard/"
                target="_blank"
              >
                <ListItemIcon className="listicon">
                  <MdDashboard />
                </ListItemIcon>
                <ListItemText className="listtext" primary="Mint" />
              </a>
            </ListItem>
          </Tooltip>
          <Tooltip title="Marketplace" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a
                href="https://marketplace.dexdaddy.com/#/app/dashboard"
                target="_blank"
              >
                <ListItemIcon className="listicon">
                  <BsFillHandbagFill />
                </ListItemIcon>
                <ListItemText className="listtext " primary="Marketplace" />
              </a>
            </ListItem>
          </Tooltip>
          <Tooltip title="Launchpad" placement="right">
            <ListItem className="ActiveLink menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a href="/">
                <ListItemIcon className="listicon">
                  <AiFillRocket />
                </ListItemIcon>
                <ListItemText
                  className="listtext activelisttext"
                  primary="Launchpad"
                />
              </a>
            </ListItem>
          </Tooltip>

          <Tooltip title="Dex" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a
                href="https://dex.dexdaddy.com/#/app/dashboard"
                target="_blank"
              >
                <ListItemIcon className="listicon">
                  <FiTrendingUp />
                </ListItemIcon>
                <ListItemText className="listtext" primary="Dex" />
              </a>
            </ListItem>
          </Tooltip>

          <Tooltip title="Farm" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a
                href="https://farm.dexdaddy.com/#/app/dashboard"
                target="_blank"
              >
                <ListItemIcon className="listicon">
                  <GiThreeLeaves />
                </ListItemIcon>
                <ListItemText className="listtext" primary="Farm" />
              </a>
            </ListItem>
          </Tooltip>

          <Tooltip title="Sale Events" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a
                href="https://sale.dexdaddy.com/#/app/dashboard"
                target="_blank"
              >
                <ListItemIcon className="listicon">
                  <BiNetworkChart />
                </ListItemIcon>
                <ListItemText className="listtext" primary="Sale Events" />
              </a>
            </ListItem>
          </Tooltip>
          <Tooltip title="Stake" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a href="#">
                <ListItemIcon className="listicon">
                  <FaHandHoldingUsd />
                </ListItemIcon>
                <ListItemText className="listtext " primary="Stake" />
              </a>
            </ListItem>
          </Tooltip>
          <Tooltip title="Metaverse" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a href="#" target="_blank">
                <ListItemIcon className="listicon">
                  <SiEquinixmetal />
                </ListItemIcon>
                <ListItemText className="listtext" primary="Metaverse" />
                {isSidebarOpened ? (
                  <span className="comingsoonimg">Coming Soon!</span>
                ) : (
                  <span className="comingsoonimgclose">Coming Soon!</span>
                )}
              </a>
            </ListItem>
          </Tooltip>
          <Tooltip title="Games" placement="right">
            <ListItem className=" menuLink LinkMenu" disableRipple>
              <span className="shape1"></span>
              <span className="shape2"></span>
              <a href="#" target="_blank">
                <ListItemIcon className="listicon">
                  <FaGamepad />
                </ListItemIcon>
                <ListItemText className="listtext" primary="Games" />
                {isSidebarOpened ? (
                  <span className="comingsoonimg">Coming Soon!</span>
                ) : (
                  <span className="comingsoonimgclose">Coming Soon!</span>
                )}
              </a>
            </ListItem>
          </Tooltip>
        </List>
      )}
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
