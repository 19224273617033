import React, { useState, useContext, useEffect, useRef } from "react";
import {
  AppBar,
  appBarCloses,
  Toolbar,
  IconButton,
  Grid,
  Box,
  Hidden,
  Popper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreAction from "./MoreAction";
import DashboardIcon from "@material-ui/icons/Dashboard";
import {
  Menu as MenuIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";
// styles
import useStyles from "./styles";
// components
import { Button } from "../Wrappers";
// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { BiDotsVertical } from "react-icons/bi";
import { useUserDispatch, signOut } from "../../context/UserContext";
import { UserContext } from "../../context/User";
import { useWeb3React } from "@web3-react/core";
import MenuLink from "./MenuLink";
import NavBar from "../../layouts/DashboardLayout/NavBar";
import ConnectWallet from "../ConnectWalletPopUp/Index";

export default function Header(props) {
  var classes = useStyles();
  const user = useContext(UserContext);
  const [openWalletConnect, setOpenWalletConnect] = useState(false);

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const { active, account, chainId, library } = useWeb3React();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const moreRef = useRef(null);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [openPop, setOpenPop] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (account) {
      setOpenWalletConnect(false);
    }
  }, [account]);

  return (
    <AppBar
      position="fixed"
      className={layoutState.isSidebarOpened ? `appBar` : `appBarCloses`}
      elevation={0}
      style={{ backgroundColor: "#000 !important" }}
    >
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} sm={2}>
          <Toolbar className={classes.toolbar}>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                onClick={() => toggleSidebar(layoutDispatch)}
                className={classNames(
                  classes.headerMenuButtonSandwich,
                  classes.headerMenuButtonCollapse
                )}
              >
                {layoutState.isSidebarOpened ? (
                  <ArrowBackIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse
                      ),
                    }}
                  />
                ) : (
                  <MenuIcon
                    classes={{
                      root: classNames(
                        classes.headerIcon,
                        classes.headerIconCollapse
                      ),
                    }}
                  />
                )}
              </IconButton>
            </Hidden>
            <Hidden smUp>
              <Link to="/">
                {" "}
                <img
                  src="../images/fav.png"
                  style={{ maxHeight: "40px", maxWidth: "40px", margin: "8px" }}
                />
              </Link>
            </Hidden>
            {/* <Hidden xsDown>
              <Link to="/">
                {" "}
                <img src="../images/logo.png" />
              </Link>
            </Hidden> */}

            <div className={classes.grow} />
          </Toolbar>
        </Grid>
        <Grid item xs={7} sm={10} align="right" className={classes.sidbar}>
          <Box className={classes.sidbar2}>
            <Typography
              variant="body2"
              component="span"
              className={classes.navLinks}
            >
              <img src="images/audited.svg" />
            </Typography>
            {account ? (
              <button
                className={classes.buttonright}
                variant="contained"
                size="large"
                //  onClick={user.connectWallet}
                style={{ color: "#fff !important" }}
              >
                Apply for IDO
              </button>
            ) : (
              <button
                className={classes.buttonright}
                variant="contained"
                size="large"
                onClick={user.connectWallet}
                style={{ color: "#fff !important" }}
              >
                CONNECT
              </button>
            )}
            <Hidden mdUp>
              <MoreAction />
            </Hidden>
            <Hidden smDown>
              {/* <MenuLink /> */}
              <IconButton onClick={handleClick}>
                <DashboardIcon />
              </IconButton>
            </Hidden>
            <Menu
              id="simple-menu"
              style={{ position: "absolute", top: "3.5%" }}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ color: "#fff" }}
              disableScrollLock={true}
            >
              {/* <MenuItem>
                <Button style={{ color: "#fff" }}>Apply for IDO</Button>
              </MenuItem> */}
              <MenuItem>
                <Button
                  to="/app/create-pool"
                  component={Link}
                  style={{ color: "#fff" }}
                >
                  Create Pool
                </Button>
              </MenuItem>
            </Menu>
          </Box>
        </Grid>
      </Grid>
    </AppBar>
  );
}
