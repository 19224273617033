import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import MomentUtils from "@date-io/moment";
// components
import Layout from "./Layout";
// import Themes from "../themes/default"
// import Themes from "../themes";
// pages
import Error from "../pages/error";
import Login from "../pages/login";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  // var { isAuthenticated } = useUserState();
  var isAuthenticated = true;
  return (
    <HashRouter>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/app/dashboard" />}
          />
          <PublicRoute
            exact
            path="/"
            render={() => <Redirect to="/app/dashboard" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          {/* <PublicRoute path='/login' component={Login} /> */}
          <Route component={Error} />
        </Switch>
      </MuiPickersUtilsProvider>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
