import tinycolor from 'tinycolor2'

// const primary = "#536DFE";
const primary = '#42e8e0'
const secondary = '#fff'
const warning = '#FFC260'
const success = '#3CD4A0'
const info = '#9013FE'

const lightenRate = 7.5
const darkenRate = 15

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: '#FFFFFF',
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: '#4A4A4A',
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
    },
    background: {
      default: '#F6F7FF',
      light: '#F3F5FF',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  overrides: {
    MuiStepLabel: {
      iconContainer: {
        '& svg': {
          color: '#d8d8d8',
        },
        '& label': {
          color: '#bebebe',
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: '#fff',
        backgroundColor: '#000000',
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: '#fff',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
        border: '1px solid #3b3b3b',
        background: 'linear-gradient( 152.97deg, #fff3 0%, #fff0 100%)',
        color: '#ccc',
        // border: "2px solid #3e3c3c",
        '&:focus-visible': {
          outlineColor: 'none',
        },
        '&:active': {
          outlineColor: 'none',
        },
        '&::placeholder': {
          color: '#fff',
        },
      },
    },

    PrivateValueLabel: {
      circle: {
        display: 'flex',
      },
    },
    MuiStepper: {
      root: {},
      horizontal: {
        // background: "#000",
        background: 'transparent',
      },
    },
    MuiSlider: {
      root: {
        color: ' #ffffff',
        height: '10px',
      },
      track: {
        height: '20px',
        borderRadius: '50px!important',
        backgroundColor: '#F8B900 !important',
      },
      rail: {
        height: '20px',
        borderRadius: '50px!important',
        backgroundColor: '#e21ae7 !important',
        backgroundColor: '#3A3A3A',
        opacity: 1,
      },
      thumb: {
        width: '30px',
        height: '30px',
        borderRadius: '0px!important',
        backgroundColor: '#f8b900',
      },
      valueLabel: {
        left: 'calc(-50% - -3px)',
      },
      mark: {
        display: 'none',
      },
    },
    MuiMenuItem: {
      root: { color: '#fff', padding: '6px' },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          // backgroundColor: "#F3F5FF !important",
          '&:focus': {
            // backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          // backgroundColor: "#303232",
        },
      },
    },

    MuiListItemText: {
      root: {
        margin: '0 !important',
        '& span': {
          '@media (max-width:991px)': {
            fontSize: '14px !important',
          },
        },
      },
    },
    MuiPaper: {
      root: { color: '#fff' },
      rounded: {
        background: '#292929',
        // background: "linear-gradient( 152.97deg, #fff3 0%, #fff0 100%)",
        // backgroundColor: " #000",
        borderRadius: '10px',
        backdropFilter: 'blur(42px)',
      },
    },
    Styled: {
      contained: {
        backgroundColor: '#536dfe00',
        border: '1.5px solid #F8B900',
        borderRadius: '50px',
        fontSize: '14px',
        fontWeight: 600,
        minWidth: '150px',
        height: '40px',
        '&:hover': {
          background: 'linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)',
        },
        '&.active': {
          background: 'linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)',
        },
      },
    },
    MuiDrawer: {
      paper: {
        overflow: 'inherit',
      },
    },
    MuiPickersDay: {
      day: {
        color: '#43d8d1',
      },
      daySelected: {
        color: '#000',
      },
    },
    MuiIconButton: {
      root: {
        flex: '0 0 auto',
        color: '#43d8d1',
        // padding: "12px",
        // overflow: "visible",
        // fontSize: "1.5rem",
        // textAlign: "center",
        // transition: "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        // borderRadius: "50%",
      },
    },
    MuiFilledInput: {
      underline: {
        '&::before': {
          display: 'none',
        },
      },
      input: {
        padding: '15px 12px 15px',
        '&::placeholder': {
          color: '#fff',
        },
      },
      root: {
        backgroundColor: '#3E3E3E',
        color: '#6A6A6A',
        borderRadius: '10px !important',
      },
    },

    MuiButton: {
      root: {
        color: '#fff',
        '&:hover': {
          backgroundColor:
            'linear-gradient(180deg, rgba(248,185,0,1) 16%, rgba(143,84,9,1) 79%)',
        },
        '&:disabled': {
          color: '#fff',
        },
      },
      contained: {
        '&:hover': {
          backgroundColor: 'linear-gradient(180deg, #F8B900 0%, #8F5409 100%)',
        },
        '&.Mui-disabled': {
          color: '#ccc',
        },
      },

      containedPrimary: {
        borderRadius: '50px',
        fontSize: '14px',
        fontWeight: 600,
        minWidth: '150px',
        height: '50px',
        backgroundColor: 'transparent',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        color: '#fff',
        transition: '0.5s',
        boxSizing: ' border-box',
        border: ' 2px solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box',
        backgroundImage:
          'linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)',
        '&:hover': {
          // background: "linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)",
          // backgroundColor: "#000000",
        },
        '&.active': {
          background: 'linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)',
        },
      },
      containedSecondary: {
        backgroundColor: 'transparent',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        color: '#fff',
        borderRadius: '50px',
        fontSize: '14px',
        fontWeight: 600,
        minWidth: '150px',
        height: '50px',
        boxSizing: ' border-box',
        border: ' 2px solid transparent',
        backgroundClip: 'padding-box, border-box',
        backgroundOrigin: 'padding-box, border-box',
        backgroundImage:
          'linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)',
        '&:hover': {
          background: 'linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)',
        },
        '&.active': {
          background: 'linear-gradient( 180deg, #8F5409 0%, #F8B900 100%)',
        },
      },
      root: {
        fontFamily: '"Poppins", sans-serif !important',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A',
      },
    },
    MuiMenu: {
      // paper: {
      //   boxShadow:
      //     "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      // },
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          // backgroundColor: "#F3F5FF !important",
          '&:focus': {
            // backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        '&:hover, &:focus': {
          // backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white',
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
        paddingLeft: 24,
      },
      head: {
        fontSize: '0.95rem',
      },
      body: {
        fontSize: '0.95rem',
        color: '#fff !important',
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
    // MuiLinearProgress: {
    //   root: {
    //     borderRadius: 3,
    //     overflow: "hidden",
    //     color: "#000",
    //   },
    // },
    MuiLinearProgress: {
      barColorPrimary: {
        backgroundColor: '#000',
      },
    },
    MuiInputBase: {
      root: {
        color: '#fff',
      },
    },
  },
}

export default defaultTheme
