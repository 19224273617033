import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import UpcomingPoolCard from "../../components/UpcomingPoolCard";
import { UserContext } from "../../context/User";
import ButtonCircularProgress from "../../components/ButtonCircularProgress";

const dummyData = [
  {
    id: "0",
    tokenName: "IDOPool",
    address: "000x00000xx00000",
    softCap: "10",
    hardCap: "100",
  },
  {
    id: "0",
    tokenName: "IDOPool",
    address: "000x00000xx00000",
    softCap: "10",
    hardCap: "100",
  },
  {
    id: "0",
    tokenName: "IDOPool",
    address: "000x00000xx00000",
    softCap: "10",
    hardCap: "100",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    // background: theme.palette.background.default,
    // background: "#000",
  },
  box: {
    padding: 20,
  },
  timelineBox: {
    background: theme.palette.primary.main,
  },
  noDataBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));
export default function Pools() {
  const classes = useStyles();
  const auth = useContext(UserContext);
  const [poolListingData, setPoolListingData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(auth.isLoading);
    setPoolListingData(auth.poolData);
  }, [auth.isLoading, auth.isUpdatingData]);
  return (
    <Box className={classes.root} py={10}>
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          style={{ color: "#fff", fontWeight: "600" }}
        >
          All Projects
        </Typography>
        <Box pt={4}>
          <Grid container spacing={3}>
            {poolListingData &&
              poolListingData.map((rowData, id) => {
                return (
                  <Grid item lg={4} sm={6} md={6} xs={12} key={id}>
                    <UpcomingPoolCard rowData={rowData} index={id} />
                  </Grid>
                );
              })}
            {/* </Slider> */}
          </Grid>

          {isLoading && (
            <Box
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <h5 style={{ color: "#fff" }}>Loading...</h5>{" "}
              <ButtonCircularProgress />
            </Box>
          )}

          {!isLoading && poolListingData && poolListingData.length === 0 && (
            <Box className={classes.noDataBox}>
              <h2>No data found!!</h2>
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  );
}
