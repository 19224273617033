import React from "react";

export default function Attributes() {
  return (
    <div style={{paddingBottom:"150px"}}>
      {" "}
      <img style={{ width: "100%" }} src='/images/artimages.png' alt='' />
    </div>
  );
}
