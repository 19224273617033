import {
  Grid,
  Box,
  Typography,
  Paper,
  Chip,
  useTheme,
  makeStyles,
} from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import { ethers } from 'ethers'
import moment from 'moment'
import AcUnitIcon from '@material-ui/icons/AcUnit'
import { useHistory } from 'react-router'
const useStyles = makeStyles((theme) => ({
  cardBody: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionHeading: {
    display: 'flex',
    // justifyContent: "space-between",
    alignItems: 'center',
    color: '#000',
  },
  dataBox: {
    textAlign: 'center',
    border: '1px solid #3e3c3c',
    borderRadius: '16px',
    padding: '4px',
    margin: '2px',
    backgroundColor: '#4141420d',
    minHeight: '90px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}))
export default function UpcomingPoolCard({ dark, rowData }) {
  const history = useHistory()
  const theme = useTheme()
  const classes = useStyles()
  let row = rowData?.data
  let finishDate = moment(parseInt(row?.FinishTime) * 1000)
  var duration = moment.duration(finishDate.diff(moment(new Date())))
  var days = Math.floor(duration.asDays())
  duration.subtract(moment.duration(days, 'days'))
  var hours = duration.hours()
  duration.subtract(moment.duration(hours, 'hours'))
  var minutes = duration.minutes()
  duration.subtract(moment.duration(minutes, 'minutes'))
  return (
    <Paper
      elevation={1}
      className="poolCardBody"
      style={{ padding: '15px' }}
      onClick={() => {
        history.push({
          pathname: '/app/pool-detail',
          search: rowData?.id.toString(),
          // search: "1",
        })
      }}
    >
      <Box className={classes.cardBody}>
        <Box className={classes.sectionHeading}>
          <AcUnitIcon fontSize="large" color="secondary" marginRight="10px" />
          <h3 style={{ color: '#ccc' }}>
            Id:
            {rowData?.id}
          </h3>
        </Box>
        <Chip
          label={
            days < 0
              ? 'Time Passed'
              : `${days} Days, ${Math.abs(hours)} Hours, ${Math.abs(
                  minutes,
                )} Min`
          }
          value="expired"
          color="primary"
          style={{
            backgroundImage:
              'linear-gradient(90deg, #2599fa -23.36%, #42E8E0 59.43%)',
            overflow: 'hidden',
          }}
        />
      </Box>
      <Box mt={2}>
        <Typography variant="h4" paragraph color={'secondary'}>
          {rowData?.tokenName ? rowData?.tokenName : 'XYZ'}
        </Typography>
        <Typography variant="body2">Token address:</Typography>
        <Typography variant="h4">
          {row?.Creator && `${row.Token.slice(0, 4)}...${row.Token.slice(-4)}`}
        </Typography>
      </Box>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} lg={4} sm={4} md={4}>
            <Box className={classes.dataBox}>
              <Typography variant="body2" style={{ paddingTop: '10px' }}>
                Start time
              </Typography>
              <Typography
                variant="h6"
                color={'secondary'}
                style={{ fontSize: '14px' }}
              >
                {rowData?.data?.StartTime
                  ? moment(
                      new Date(parseInt(rowData?.data?.StartTime) * 1000),
                    ).format('YYYY-MM-DD HH:mm')
                  : '0'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} sm={4} md={4}>
            <Box className={classes.dataBox}>
              <Typography variant="body2">Hard Cap</Typography>
              <Typography variant="h6" color={'secondary'}>
                {row?.StartAmount
                  ? ethers.utils.formatEther(row.StartAmount)
                  : '0'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={4} sm={4} md={4}>
            <Box className={classes.dataBox}>
              <Typography variant="body2">Swap Ratio</Typography>
              <Typography
                variant="h6"
                color={'secondary'}
                style={{ fontSize: '14px' }}
              >
                1&nbsp;AVAX&nbsp;=&nbsp; <br />
                {row?.Rate ? row?.Rate.toString() : '0'}
                &nbsp;Token
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}
