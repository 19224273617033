/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext } from "react";
import { useLocation, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
// import CreatePool from "src/layouts/DashboardLayout/CreatePool";
// import SocialLinks from "src/components/SocialLinks";

import { Link, useHistory } from "react-router-dom";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Button,
  ListItem,
} from "@material-ui/core";
import {
  PieChart as PieChartIcon,
  Info as InfoIcon,
  TrendingUp,
} from "react-feather";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import NavItem from "./NavItem";
// import { AuthContext } from "src/context/Auth";
import WhiteListDialog from "../../../pages/Whitelist";
import SelectBlockChainPopup from "../../../components/SelectBlockChainPopup";
import SocialLinks from "../../../components/SocialLinks";
import { AuthContext } from "../../../context/Auth";
import CreatePool from "../CreatePool";
// import SelectBlockChainPopup from "src/components/SelectBlockChainPopup";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.dark,
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: "calc(100% - 80px)",
    background: theme.palette.background.dark,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  let sections = [
    {
      items: [
        {
          title: "Dashboard",
          icon: PieChartIcon,
          href: "/dashboard",
        },
        {
          title: "My Investments",
          icon: TrendingUp,
          href: "/my-investments",
        },
        {
          title: "Created Pools",
          icon: InfoIcon,
          href: "/created-pools",
        },
      ],
    },
  ];

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const auth = useContext(AuthContext);
  const [openWhiteList, setOpenWhiteList] = useState(false);
  const [openSelectBlockchain, setOpenSelectBlockchain] = useState(false);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height='100%' display='flex' flexDirection='column'>
      {openWhiteList && (
        <WhiteListDialog
          open={openWhiteList}
          close={() => setOpenWhiteList(false)}
        />
      )}
      <Hidden smUp>
        <Box my={2} alignItems='center'>
          <Button
            variant='contained'
            color='primary'
            style={{ marginRight: 10 }}
            onClick={() => setOpenSelectBlockchain(true)}
          >
            Select Blockchain
          </Button>

          <Button
            variant='contained'
            color='primary'
            onClick={() => setOpen(true)}
            // disabled={!auth.userLoggedIn}
            style={
              { color: "white", border: "1px solid red" }
              // !auth.userLoggedIn
              // ?
              // : {}
            }
          >
            Create Pool
          </Button>
          {/*  */}
          <Button
            variant='contained'
            color='primary'
            // onClick={() => setOpen(true)}
            onClick={() => history.push("/add-kyc")}
            style={
              // !auth.userLoggedIn
              //   ?
              {
                color: "white",
                border: "1px solid red",
                marginRight: 10,
              }
              // : { marginRight: 10 }
            }
            // disabled={!auth.userLoggedIn}
          >
            KYC
          </Button>

          <Button
            variant='contained'
            color='primary'
            style={
              // !auth.userLoggedIn
              //   ?
              {
                color: "white",
                border: "1px solid red",
                marginRight: 10,
              }
              // : { marginRight: 10 }
            }
            // disabled={!auth.userLoggedIn}
            // onClick={() => setModalOpen(true)}
          >
            Claim Tokens
          </Button>

          {/*  */}
          <Button
            style={
              // auth.userLoggedIn
              // ?
              { color: "white", border: "1px solid red" }
              // : {}
            }
            // disabled={auth.userLoggedIn}
            variant='contained'
            color='secondary'
            // onClick={() =>
            //   auth.userLoggedIn
            //     ? auth.disconnectWallte()
            //     : auth.connectToWallet()
            // }
          >
            Wallet
            {/* {auth.userLoggedIn ? "Disonnect" : "Connect"} */}
          </Button>
        </Box>
        {open && <CreatePool close={() => setOpen(false)} open={open} />}
      </Hidden>
      <Box>
        {sections.map((section, i) => {
          return (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          );
        })}
        {/* {auth.isAdmin && (
          <List style={{ paddingTop: 0 }}>
            <ListItem style={{ paddingTop: 0 }}>
              <Button
                fullWidth
                style={{
                  color: "#969ba1",
                  fontWeight: 500,
                  fontSize: "0.875rem",
                  paddingLeft: 8,
                  justifyContent: "left",
                }}
                onClick={() => setOpenWhiteList(true)}
              >
                <VerifiedUserIcon size={20} style={{ marginRight: 18 }} />{" "}
                Whitelist
              </Button>
            </ListItem>
          </List>
        )} */}
      </Box>
      <Box flexGrow={1} />
      <SocialLinks />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        {openSelectBlockchain && (
          <SelectBlockChainPopup
            open={openSelectBlockchain}
            handleClose={() => setOpenSelectBlockchain(false)}
          />
        )}
        <Drawer
          anchor='left'
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant='temporary'
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor='left'
          classes={{ paper: classes.desktopDrawer }}
          open
          variant='persistent'
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
