import React, { useContext, useState, useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import {
  AppBar,
  Container,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Box,
  Slide,
  Dialog,
  Typography,
  Step,
  Stepper,
  StepLabel,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
// import { AuthContext } from "../../../context/Auth";
import { UserContext } from '../../../context/User'
import MomentUtils from '@date-io/moment'
import PlatformAbi, {
  Erc20TokenAbi,
  ERC20tokenAddress,
  AvalancheAddress,
  ethAddress,
  USDTAvalanche,
} from '../../../context/abi'
import { ethers } from 'ethers'
import { DateTimePicker } from '@material-ui/pickers'
// import ButtonCircularProgress from "src/components/ButtonCircularProgress";
import moment from 'moment'
import Alert from '@material-ui/lab/Alert'
import { toast } from 'react-toastify'
import { useWeb3React } from '@web3-react/core'
import { getContract, swichNetworkHandler } from '../../../utils'
// import { getCurrentContractAddress } from "../../../components/constants";
import { useHistory } from 'react-router'
import { getCurrentContractAddress } from '../../../constants'
import ButtonCircularProgress from '../../../components/ButtonCircularProgress'
import ConnectWallet from '../../../components/ConnectWalletPopUp/Index'
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  paper: {
    backgroundImage: 'url(images/pool.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
  },
}))

function getSteps() {
  return ['1. Token information', '2. Swaps Rule', '3. Confirm']
}

export function GetStepContent({
  stepIndex,
  handleBack,
  handleNext,
  formDataRef,
  saveStepOneData,
  saveStepTwoData,
  saveStepThreeData,
  createPoolHandler,
}) {
  const steps = getSteps()
  const { account, library, chainId } = useWeb3React()
  const auth = useContext(UserContext)
  const [isSubmit, setIsSubmit] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [openWalletConnect, setOpenWalletConnect] = useState(false)

  const [selectedDate, handleDateChange] = useState(
    new Date(formDataRef.pullRunningTime),
  )
  const [selectstartDate, handleStartDateChange] = useState(
    new Date(formDataRef.poolStartTime),
  )
  const [formData, setFormData] = React.useState({
    tokenAddress: formDataRef.tokenAddress,
    tokenName: formDataRef.tokenName,
    tokenSymbol: formDataRef.tokenSymbol,
    tokenDecimal: formDataRef.tokenDecimal,
    toToken: formDataRef.toToken,
    tokenValue: formDataRef.tokenValue,
    balance: formDataRef.balance,
    pullRunningTime: formDataRef.pullRunningTime,
    poolStartTime: formDataRef.poolStartTime,
    maxLiquidityETH: formDataRef.maxLiquidityETH,
    releaseTimeForFirstInterval: formDataRef.releaseTimeForFirstInterval,
    releaseTimeForSecondInterval: formDataRef.releaseTimeForSecondInterval,
    releasePercentageForFirstInterval:
      formDataRef.releasePercentageForFirstInterval,
    releasePercentageForSecondInterval:
      formDataRef.releasePercentageForSecondInterval,
  })
  useEffect(() => {
    if (account) {
      setOpenWalletConnect(false)
    }
  }, [account])

  const _onInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    const temp = { ...formData, [name]: value }

    setFormData(temp)
  }

  const _onInputDateChange = (value, name) => {
    console.log('value', value)
    console.log('name', name)
    const temp = { ...formData, [name]: new Date(value) }
    setFormData(temp)
  }

  const approveToken = async () => {
    // if (auth.selectedBlockchain.chainId == chainId) {
    try {
      setIsLoading(true)
      const daiWithSigner = getContract(
        formData.tokenAddress,
        Erc20TokenAbi,
        library,
        account,
      )
      console.log('daiContract====>>>>', daiWithSigner)

      const amt1 =
        parseFloat(formData.balance) * parseFloat(formData.tokenValue)

      const amt = ethers.utils.parseEther(amt1.toString())

      let tx = await daiWithSigner.approve(AvalancheAddress, amt.toString()) //amountof token
      await tx.wait()
      toast.success('Your transaction has been approved successfully')
      handleNext()
      saveStepTwoData(formData, selectedDate, selectstartDate)

      setIsLoading(false)
      setIsError(true)
    } catch (err) {
      toast.error(err.message)
      console.error('ERROR: ', err)
      setIsLoading(false)
    }
  }

  const nextHandler = async () => {
    setIsError(false)
    setIsSubmit(true)
    if (stepIndex === 0) {
      if (
        formData.tokenAddress !== ''
        // formData.toToken !== "" &&
      ) {
        setIsSubmit(false)
        handleNext()
        saveStepOneData(formData)
      }
    }
    if (stepIndex === 1) {
      if (formData.tokenValue !== '' && formData.balance !== '') {
        if (formData.tokenValue >= '1') {
          if (account) {
            setIsSubmit(false)
            await approveToken()
          } else {
            toast.warn('Connect your wallet')
          }
        } else {
          toast.error('Token value should be greater or equal to 1')
        }
      }
    }
    if (stepIndex === 2) {
      if (formData.maxLiquidityETH !== '') {
        setIsSubmit(false)
        handleNext()
        saveStepThreeData(formData)
      }
    }
    if (stepIndex === steps.length - 1) {
      setIsLoading(true)
      await createPoolHandler(formData)
      // clearFormData();
      // setIsLoading(false);
    }
  }

  const tokenDetailsHandler = async () => {
    console.log('tokenADDRESS', formData.tokenAddress)
    if (formData.tokenAddress !== '') {
      try {
        // const contractObj = new ethers.Contract(
        //   formData.tokenAddress,
        //   Erc20TokenAbi,
        //   auth.userData.provider
        // );
        const objWithSigner = getContract(
          formData.tokenAddress,
          Erc20TokenAbi,
          library,
          account,
        )

        //await contractObj.connect(auth.userData.signer);
        console.log('contract---', objWithSigner)
      } catch (err) {
        console.log(err)
      }
    }
  }

  switch (stepIndex) {
    case 0:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              // label="Token Contract Address"
              margin="normal"
              variant="outlined"
              placeholder="Token Contract Address"
              fullWidth
              name="tokenAddress"
              value={formData.tokenAddress}
              onChange={_onInputChange}
              error={isSubmit && formData.tokenAddress === ''}
              helperText={
                isSubmit &&
                formData.tokenAddress === '' &&
                'Please Enter Token Contract Address'
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Pool Start Time"
                placeholder="Pool Start Time"
                inputVariant="outlined"
                value={selectstartDate}
                onChange={handleStartDateChange}
                fullWidth
                name="campaignDate"
                disablePast
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Pool Running Time"
                placeholder="Pool Running Time"
                inputVariant="outlined"
                value={selectedDate}
                onChange={handleDateChange}
                fullWidth
                name="campaignDate"
                disablePast
                minDate={selectstartDate}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              {/* <InputLabel id="demo-simple-select-outlined-label">
                To Token
              </InputLabel> */}
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                // label="To Token"
                defaultValue="AVAX"
                name="toToken"
                // value={formData.toToken.}
                onChange={_onInputChange}
                disableScrollLock={true}
                error={isSubmit && formData.toToken === ''}
              >
                <MenuItem value={'AVAX'}>AVAX</MenuItem>
                <MenuItem value={'USDT'}>USDT</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Box>
              {account ? (
                <Box mt={5}>
                  <Button disabled={true} onClick={handleBack}>
                    Back
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={nextHandler}
                  >
                    {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </Box>
              ) : (
                <Box mt={5}>
                  {' '}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenWalletConnect(true)}
                  >
                    Connect Wallet
                  </Button>{' '}
                </Box>
              )}
            </Box>
          </Grid>
          {openWalletConnect && (
            <ConnectWallet
              open={openWalletConnect}
              handleClose={() => setOpenWalletConnect(false)}
            />
          )}
        </Grid>
      )
    case 1:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            {/* <Box className="height100 d-flex">
              <Typography variant="h2">
                1 {formData.toToken && formData.toToken.name} =
              </Typography>
            </Box> */}
          </Grid>
          <Grid item xs={12} md={12}>
            <TextField
              type="number"
              // label="Token Value"
              placeholder="Token Value"
              margin="normal"
              variant="outlined"
              fullWidth
              min="1"
              step={1.0}
              name="tokenValue"
              value={formData.tokenValue}
              onChange={_onInputChange}
              InputProps={{ inputProps: { min: 1, max: 100 } }}
              error={isSubmit && formData.tokenValue === ''}
              helperText={
                isSubmit &&
                formData.tokenValue === '' &&
                'Please Enter Token Value'
              }
            />
            {/* <span style={{ margin: '0', color: '#fff' }}>
              Please enter value in 1, 2, 3...
            </span> */}
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              // label="Amount of tokens you want to lock"
              placeholder="Amount of tokens you want to lock"
              margin="normal"
              variant="outlined"
              fullWidth
              name="balance"
              value={formData.balance}
              onChange={_onInputChange}
              error={isSubmit && formData.balance === ''}
              helperText={
                isSubmit && formData.balance === '' && 'Please Enter Amount'
              }
            />
          </Grid>

          {/* <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth>
              <DateTimePicker
                label="Pool Running Time"
                inputVariant="outlined"
                value={selectedDate}
                onChange={handleDateChange}
                fullWidth
                name="campaignDate"
                disablePast
              />
            </FormControl>
          </Grid> */}
          {isError && (
            <Box py={2}>
              <Alert severity="error">{'Error In Transaction'}</Alert>
            </Box>
          )}
          <Grid>
            <Box>
              <Box mt={5}>
                <Button onClick={handleBack} disabled={isLoading}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  onClick={nextHandler}
                >
                  {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                  {isLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )
    case 2:
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              type="number"
              // label="Max liquidity"
              placeholder="Max liquidity"
              placeholder="1"
              margin="normal"
              variant="outlined"
              fullWidth
              readonly
              name="maxLiquidityETH"
              value={
                formData.balance && formData.tokenValue
                  ? parseFloat(formData.balance) *
                    parseFloat(formData.tokenValue)
                  : 0
              }
              onChange={_onInputChange}
              disabled
              // error={isSubmit && formData.maxLiquidityETH === ''}
              // helperText={isSubmit && formData.maxLiquidityETH === '' && "Please Enter Max Liquidity ETH"}
            />
          </Grid>
          <Grid>
            <Box>
              <Box mt={5}>
                <Button onClick={handleBack} disabled={isLoading}>
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={nextHandler}
                  disabled={isLoading}
                >
                  {stepIndex === steps.length - 1 ? 'Finish' : 'Next'}
                  {isLoading && <ButtonCircularProgress />}
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )
    default:
      return 'Unknown stepIndex'
  }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function CreatePool({ close, open }) {
  const classes = useStyles()
  const { account, library, chainId } = useWeb3React()
  const [activeStep, setActiveStep] = React.useState(0)
  const steps = getSteps()
  const auth = useContext(UserContext)
  const history = useHistory()
  const [formData, setFormData] = React.useState({
    tokenAddress: '',
    tokenName: '',
    tokenSymbol: '',
    tokenDecimal: '',
    toToken: '',
    tokenValue: '',
    balance: '',
    poolStartTime: new Date(),
    pullRunningTime: new Date(),
    maxLiquidityETH: '',
    releaseTimeForFirstInterval: new Date(),
    releaseTimeForSecondInterval: new Date(),
    releasePercentageForFirstInterval: '',
    releasePercentageForSecondInterval: '',
  })

  const saveStepOneData = (data) => {
    console.log('saveStepOneData', data)
    const temp = { ...formData }
    setFormData({
      ...temp,
      tokenAddress: data.tokenAddress,
      tokenName: data.tokenName,
      tokenSymbol: data.tokenSymbol,
      tokenDecimal: data.tokenDecimal,
      toToken: data.toToken,
      releaseTimeForFirstInterval: data.releaseTimeForFirstInterval,
      releaseTimeForSecondInterval: data.releaseTimeForSecondInterval,
      releasePercentageForFirstInterval: data.releasePercentageForFirstInterval,
      releasePercentageForSecondInterval:
        data.releasePercentageForSecondInterval,
    })
  }

  const saveStepTwoData = (data, selectedDate, selectstartDate) => {
    const temp = { ...formData }
    console.log('data.balance', data?.balance)
    setFormData({
      ...temp,
      tokenValue: data.tokenValue,
      balance: data.balance,
      pullRunningTime: selectedDate,
      poolStartTime: selectstartDate,
    })
  }

  const saveStepThreeData = (data) => {
    const temp = { ...formData }
    setFormData({
      ...temp,
      maxLiquidityETH: data.maxLiquidityETH,
    })
  }

  const createPoolHandler = async (data) => {
    try {
      const PlatformWithSigner = getContract(
        AvalancheAddress,
        PlatformAbi,
        library,
        account,
      )
      console.log('contract----', PlatformWithSigner)
      const amt1 =
        parseFloat(formData.balance) * parseFloat(formData.tokenValue)

      const amt = ethers.utils.parseEther(amt1.toString())
      const addressCheck =
        formData.toToken === 'AVAX' ? ethAddress : USDTAvalanche
      let tx = await PlatformWithSigner.CreatePool(
        formData.tokenAddress, //tokenAddress
        moment(formData.pullRunningTime).unix(), //finishTime
        parseInt(formData.tokenValue), //rate
        parseInt(formData.tokenValue), //poolsRate
        amt.toString(), //startAmount
        moment(formData.pullRunningTime).unix(), //finishTime
        addressCheck,
        moment(formData.poolStartTime).unix(),
      )
      await tx.wait()

      toast.success('Your pool has been created successfully')
      setActiveStep(0)
      // clearFormData();
      auth.getDashboardPools()
      history.push('/app/pools-dashboard')
    } catch (err) {
      toast.error(err.message)
      console.log('ERROR', err)
    }
  }

  const clearFormData = () => {
    setFormData({
      tokenAddress: '',
      tokenName: '',
      tokenSymbol: '',
      tokenDecimal: '',
      toToken: '',
      tokenValue: '',
      balance: '',
      pullRunningTime: new Date(),
      maxLiquidityETH: '',
    })
  }

  const handleNext = (data) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box>
      <Container>
        <Stepper>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box>
          {activeStep === steps.length ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              <Button onClick={handleReset} variant="contained" color="primary">
                Reset
              </Button>
            </div>
          ) : (
            <Box>
              {/* {getStepContent(activeStep, handleBack, handleNext, formData)} */}
              <GetStepContent
                stepIndex={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                formDataRef={formData}
                saveStepOneData={(data) => saveStepOneData(data)}
                saveStepTwoData={(data, selectedDate) =>
                  saveStepTwoData(data, selectedDate)
                }
                saveStepThreeData={(data) => saveStepThreeData(data)}
                createPoolHandler={(data) => createPoolHandler(data)}
              />
            </Box>
          )}
        </Box>
      </Container>
    </Box>
  )
}
