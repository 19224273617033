import React from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import {
  Box,
  makeStyles,
  Typography as MaterilTypography,
} from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
//icons
import DashboardTable from '../../pages/dashboard/DashboardTable'
import CreatePool from '../../layouts/DashboardLayout/CreatePool'
// styles
import Details from '../../pages/NFTDetails/Details'
// components
import Header from '../Header'
import Sidebar from '../Sidebar'
import { useWeb3React } from '@web3-react/core'

// pages
import Dashboard from '../../pages/dashboard'
// import Typography from "../../pages/typography";
import UserSearchNFT from '../../pages/UserSearchNFT/'

import NFTDetails from '../../pages/NFTDetails'
import MyWallet from '../../pages/Wallet/Index'
import MintNFT from '../../pages/MintNFT/'
import Attributes from '../../pages/Attributes/'

import { useLayoutState } from '../../context/LayoutContext'
import { ACTIVE_NETWORK, NetworkContextName } from '../../constants'
import Footer from './Footer'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden',
  },
  content: {
    flexGrow: 1,
    padding: '24px 100px 0',
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    // width: `calc(100vw - 240px)`,
    minHeight: '100vh',
    paddingTop: '80px',
    zIndex: '1',
    overflow: 'hidden',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      padding: '24px 24px',
      paddingTop: '80px',
    },
  },
  contentShift: {
    width: `calc(100vw - 240px)`,
    position: 'absolute',
    right: '0',
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    [theme.breakpoints.down('sm')]: {
      width: `100%`,
    },
  },
  fakeToolbar: {
    ...theme.mixins.toolbar,
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15,
    },
  },

  buttonright: {
    background: 'linear-gradient(90deg, #2599FA -23.36%, #42E8E0 59.43%)',
    fontSize: '14px ',
    border: '1px solid transparent',
    fontWeight: 600,
    height: '44px ',
    color: '#FFFFFF',
    minWidth: '135px ',
    borderRadius: '5px',
    boxShadow: 'none ',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      height: '45px ',
      minWidth: '120px ',
    },
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      color: '#fff',
      transition: '0.5s',
      boxSizing: ' border-box',
      border: ' 2px solid transparent',
      backgroundClip: 'padding-box, border-box',
      backgroundOrigin: 'padding-box, border-box',
      backgroundImage:
        'linear-gradient(#000, #000), linear-gradient(#2599fa, #42E8E0)',
    },
  },
  shade: {
    position: 'fixed',
    left: 'auto',
    top: 'auto',
    right: '10%',
    bottom: '10%',
    transform: 'rotate(45deg)',
    zIndex: ' -1',
    width: ' 300px',
    height: '300px',
    borderRadius: ' 1000px',
    backgroundImage:
      'radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%)',
    opacity: '0.55',
    filter: 'blur(100px)',
  },
  shade2: {
    position: 'fixed',
    left: 'auto',
    top: '10%',
    right: '10%',
    bottom: 'auto',
    zIndex: ' -1',
    width: ' 300px',
    height: '300px',
    borderRadius: ' 1000px',
    backgroundImage:
      'radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(18 52 140 / 58%) 0%, rgb(18 52 140 / 55%) 95.78%)',
    opacity: '0.55',
    filter: 'blur(100px)',
  },
  shade3: {
    position: 'fixed',
    left: '10%',
    top: '21%',
    right: 'auto',
    bottom: 'auto',
    transform: 'rotate(45deg)',
    zIndex: ' -1',
    width: ' 250px',
    height: '250px',
    borderRadius: ' 1000px',
    backgroundImage:
      'radial-gradient(36.67% 9.68% at 67.26% 8.27%, rgb(42 123 135) 0%, rgb(39 121 124) 95.78%)',
    opacity: '0.55',
    filter: 'blur(100px)',
  },
  mainBox: {
    minHeight: 'calc(100vh - 185px)',
  },
}))
function Layout(props) {
  const classes = useStyles()

  var layoutState = useLayoutState()
  const { account, chainId } = useWeb3React()
  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <Box className={classes.mainBox}>
            <div className={classes.shade}></div>
            <div className={classes.shade2}></div>
            <div className={classes.shade3}></div>
            {/* <div className={classes.fakeToolbar} /> */}
            <Switch>
              <Route path="/app/dashboard" component={Dashboard} />
              <Route path="/app/search-NFT" component={UserSearchNFT} />
              <Route path="/app/NFT-details" component={NFTDetails} />
              <Route path="/app/wallet" component={MyWallet} />
              {/* <Route path="/app/mint" component={MintNFT} /> */}
              <Route path="/app/typography" component={Dashboard} />
              <Route path="/app/attributes" component={Attributes} />
              <Route path="/app/pools-dashboard" component={DashboardTable} />
              <Route path="/app/create-pool" component={CreatePool} />
              <Route path="/app/pool-detail" component={Details} />
              {/* <Route path="/app/tables" component={Tables} /> */}
              {/* <Route path="/app/test" component={Test} /> */}
              {/* <Route path="/app/notifications" component={Notifications} /> */}
              <Route
                exact
                path="/app/ui"
                render={() => <Redirect to="/app/ui/icons" />}
              />
              {/* <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} /> */}
            </Switch>
          </Box>

          <Footer />
        </div>
      </>
      {/* {account && (
        <Dialog
          open={account && chainId !== ACTIVE_NETWORK}
          minWidth='md'
          fullWidth
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {"Netwok Change Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Box ml={3} pb={3}>
                <MaterilTypography style={{ color: "black" }}>
                  Please switch to {NetworkContextName} network
                </MaterilTypography>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )} */}
    </div>
  )
}

export default withRouter(Layout)
