import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import useIsMountedRef from '../../components/useIsMountedRef'
import { UserContext } from '../../context/User'
import {
  Button,
  Container,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core'
// import { AuthContext } from "src/context/Auth";
// import JoinPool from "src/layouts/DashboardLayout/JoinPool";
// import WhiteListDialog from "src/components/WhiteListDialog";
import UpcomingPoolCard from '../../components/UpcomingPoolCard'
import { useWeb3React } from '@web3-react/core'
import ButtonCircularProgress from '../../components/ButtonCircularProgress'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'ID',
  },
  { id: 'poolName', numeric: false, disablePadding: false, label: 'Pool Name' },
  {
    id: 'tokenAddress',
    numeric: false,
    disablePadding: false,
    label: 'Token Address',
  },
  {
    id: 'swapRatio',
    numeric: false,
    disablePadding: false,
    label: 'Swap Ratio',
  },
  { id: 'tokenCap', numeric: false, disablePadding: false, label: 'Token Cap' },
  { id: 'timeLeft', numeric: false, disablePadding: false, label: 'Time Left' },
  { id: 'joinPool', numeric: true, disablePadding: false, label: 'Join Pool' },
]

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          return (
            <React.Fragment key={headCell.id}>
              <TableCell
                align={headCell.numeric ? 'right' : 'left'}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={createSortHandler(headCell.id)}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            </React.Fragment>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    color: '#ffffff',
    paddingBottom: '200px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 900,
    background: theme.palette.background.light,
    '& td': {
      color: '#ffffff',
      borderBottom: '1px solid #414142',
    },
    '& th': {
      color: '#ffffff',
      borderBottom: '1px solid #414142',
    },
    '& .MuiTableSortLabel-active': {
      color: theme.palette.primary.main,
    },
    '& .MuiTableRow-hover:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  filterBox: {
    // background: theme.palette.background.light,
    backgroundColor: '#14bbf5',
    borderRadius: '20px',
  },
}))
const dummyData = [
  {
    id: '0',
    tokenName: 'IDOPool',
    address: '000x00000xx00000',
    softCap: '10',
    hardCap: '100',
  },
  {
    id: '0',
    tokenName: 'IDOPool',
    address: '000x00000xx00000',
    softCap: '10',
    hardCap: '100',
  },
  {
    id: '0',
    tokenName: 'IDOPool',
    address: '000x00000xx00000',
    softCap: '10',
    hardCap: '100',
  },
]
function EnhancedTable({ type }) {
  const { account, library, chainId } = useWeb3React()
  const auth = useContext(UserContext)
  const isMountedRef = useIsMountedRef()
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdatingData, setIsUpdatingData] = useState(false)
  const classes = useStyles()

  const [poolListingData, setPoolListingData] = useState([])
  const [upCommingPoolsList, setUpcomingPools] = useState([])
  const [expiredPoolsList, setExpiredPoolList] = useState([])
  useEffect(() => {
    setIsLoading(auth.isLoading)
    setIsUpdatingData(auth.isUpdatingData)
    // setPoolListingData(auth.poolData);
    filterFunForOngoingPools(auth.poolData)
  }, [auth.isLoading, auth.isUpdatingData])

  const inputBoxSearchHandler = (e) => {
    let dataTosort = auth.poolData

    const result = dataTosort.filter((data) => {
      if (e.target.value.length < 6) {
        return (
          data.id.toString() === e.target.value.toString() ||
          data?.tokenName.toLowerCase() === e.target.value.toLowerCase()
        )
      } else {
        return data.data.Creator === e.target.value
      }
    })
    if (e.target.value) {
      setPoolListingData(result)
    } else {
      setPoolListingData(dataTosort)
    }
  }
  const filterFunForOngoingPools = async (data) => {
    try {
      const currentTimeInSeconds = Math.floor(Date.now() / 1000)
      const searchField = currentTimeInSeconds.toString()
      //ongoingPools--
      const filteredData = data.filter((item) => {
        return (
          searchField >= item.data.StartTime &&
          searchField <= item.data.FinishTime
        )
      })
      setPoolListingData(filteredData)
      //expiredPools--
      const filteredExpiredpools = data.filter((item) => {
        return item.data.FinishTime <= searchField
      })
      setExpiredPoolList(filteredExpiredpools)
      //upcomingPools--
      const filteredUpcomingPools = data.filter((item) => {
        return item.data.StartTime >= searchField
      })
      setUpcomingPools(filteredUpcomingPools)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={12} xs={12}>
            <Box mb={3}>
              <TextField
                fullWidth
                placeholder="Search by Pool ID or Pool name"
                variant="outlined"
                disabled={isLoading}
                onChange={(e) => inputBoxSearchHandler(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={8}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Typography
                variant="h3"
                style={{ color: '#ccc', marginBottom: '20px' }}
              >
                Ongoing Sales
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {poolListingData &&
              poolListingData.map((rowData, id) => {
                return (
                  <Grid item lg={4} sm={6} md={6} xs={12}>
                    <UpcomingPoolCard rowData={rowData} index={id} />
                  </Grid>
                )
              })}
          </Grid>
          {!isLoading && poolListingData && poolListingData.length === 0 && (
            <Box mt={2} textAlign="center">
              <Typography style={{ color: '#ccc' }}>No Data Found</Typography>
            </Box>
          )}

          {isLoading && (
            <Box
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <h5 style={{ color: '#ccc' }}>Loading...</h5>{' '}
              <ButtonCircularProgress />
            </Box>
          )}
        </Box>
        <Box mt={8}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Typography
                variant="h3"
                style={{ color: '#ccc', marginBottom: '20px' }}
              >
                Upcoming Sales
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {upCommingPoolsList &&
              upCommingPoolsList.map((rowData, id) => {
                return (
                  <Grid item lg={4} sm={6} md={6} xs={12}>
                    <UpcomingPoolCard rowData={rowData} index={id} />
                  </Grid>
                )
              })}
          </Grid>
          {!isLoading && upCommingPoolsList && upCommingPoolsList.length === 0 && (
            <Box mt={2} textAlign="center">
              <Typography style={{ color: '#ccc' }}>No Data Found</Typography>
            </Box>
          )}

          {isLoading && (
            <Box
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <h5 style={{ color: '#ccc' }}>Loading...</h5>{' '}
              <ButtonCircularProgress />
            </Box>
          )}
        </Box>
        <Box mt={8}>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Typography
                variant="h3"
                style={{ color: '#ccc', marginBottom: '20px' }}
              >
               Completed Sales
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {expiredPoolsList &&
              expiredPoolsList.map((rowData, id) => {
                return (
                  <Grid item lg={4} sm={6} md={6} xs={12}>
                    <UpcomingPoolCard rowData={rowData} index={id} />
                  </Grid>
                )
              })}
          </Grid>
          {!isLoading && expiredPoolsList && expiredPoolsList.length === 0 && (
            <Box mt={2} textAlign="center">
              <Typography style={{ color: '#ccc' }}>No Data Found</Typography>
            </Box>
          )}

          {isLoading && (
            <Box
              p={1}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <h5 style={{ color: '#ccc' }}>Loading...</h5>{' '}
              <ButtonCircularProgress />
            </Box>
          )}
        </Box>
      </Container>
    </div>
  )
}

export default React.memo(EnhancedTable)
