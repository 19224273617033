import React, { useRef, useState, memo } from "react";
import {
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { BiDotsVertical } from "react-icons/bi";
// import { Edit, Trash2 } from "react-feather";

const useStyles = makeStyles((theme) => ({
  menu: {
    width: 130,
    maxWidth: "100%",
    "& ul": {
      "& li": {
        "&:focus": {
          backgroundColor: "#f3f5ff00 !important",
        },
      },
    },
  },
}));

function MoreAction(props) {
  const { editTodo, deleteTodo, ...rest } = props;
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  return (
    <>
      <Tooltip title="More options">
        <IconButton
          onClick={handleMenuOpen}
          ref={moreRef}
          {...rest}
          className={classes.more}
        >
          <BiDotsVertical />
        </IconButton>
      </Tooltip>
      {openMenu && (
        <Menu
          anchorEl={moreRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handleMenuClose}
          open={openMenu}
          PaperProps={{ className: classes.menu }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => history.push("/app/create-pool")}>
            <ListItemText className={classes.navLinks} primary=" Create-pool" />
          </MenuItem>
          <MenuItem>
            <img
              className={classes.navLinks}
              src="images/audited.svg"
              style={{ width: "100%" }}
            />
          </MenuItem>
          {/* <MenuItem onClick={() => history.push("/app/attributes")}>
            <ListItemText
              className={classes.navLinks}
              primary=" Apply for IDO"
            />
          </MenuItem> */}
          {/* <MenuItem>
            <ListItemText
              className={classes.navLinks}
              primary='       Daddy 025$'
            />
          </MenuItem> */}
        </Menu>
      )}
    </>
  );
}

export default memo(MoreAction);
