import React from 'react'
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  // Icon,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import useStyles from './styles'
import { Link } from 'react-router-dom'
import { CallMade, Telegram } from '@material-ui/icons'
import {} from 'react-feather'
import Pools from './Pools'
export default function Banner() {
  const classes = useStyles()
  return (
    <>
      <Box className="home-banner">
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Box className={classes.textbox}>
                <Typography
                  color="primary"
                  variant="h1"
                  // style={{ fontWeight: "600" }}
                >
                 Get early access to 
the ideas of tomorrow


                </Typography>
                <Typography
                  color="secondary"
                  variant="h6"
                  style={{ color: '#ccc' }}
                >
                 Highly-vetted ideas and teams you can trust. 
Supported by industry-leading creators and funds.
                </Typography>
              </Box>
              <Box mt={4}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<CallMade />}
                  style={{
                    marginRight: 20,
                    marginBottom: 20,
                  }}
                  to="/app/pools-dashboard"
                  component={Link}
                >
                  View All IDO Pools
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  // startIcon={<Telegram />}
                  style={{ marginBottom: 20 }}
                  onClick={() => {
                    window.open(
                      'https://snowtrace.io/',
                    )
                  }}
                >
                 Smart Contract
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} textAlign="center">
              <Box
                textAlign="center"
                style={{ maxWidth: '450px', margin: '0 auto' }}
              >
                <img src="./images/bannerImgNew.png" alt="" width="100%" />
              </Box>
            </Grid>
          </Grid>
          <Box my={8} className="white-text">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="zoom-in-up"
                data-aos-delay={0}
              >
                <div
                  className="single-features"
                  // style={{ backgroundColor: "#00bdffc2" }}
                >
                  {/* <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <img src="/images/banner.jpg" alt="banner" width="100%" />
                  </Box> */}
                  <Box p={3}>
                  <img src="images/fundraising.png" alt="icons" /> 
                    <Typography variant="h3" color="inherit">
                    Fair and Equitable Fundraising
                    </Typography>
                    <Typography color="inherit" variant="body2">
                     Our unique staking and distribution methodology ensures broad participation.
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="zoom-in-up"
                data-aos-delay={0}
              >
                <div
                  className="single-features"
                  // style={{ backgroundColor: "#00bdffc2" }}
                >
                  {/* <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <img src="/images/banner.jpg" alt="banner" width="100%" />
                  </Box> */}
                  <Box p={3}>
                  <img src="images/lock.png" alt="icons" />
                    <Typography variant="h3" color="inherit">
                      Permissionless <br/> Listing
                    </Typography>
                    <Typography color="inherit" variant="body2">
                     Our emphasis on people and teams creates strong starts for new projects.
                    </Typography>
                  </Box>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                data-aos="zoom-in-up"
                data-aos-delay={0}
              >
                <div
                  className="single-features"
                  // style={{ backgroundColor: "#00bdffc2" }}
                >
                  {/* <Box
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <img src="/images/banner.jpg" alt="banner" width="100%" />
                  </Box> */}
                  <Box p={3}>
                  <img src="images/distribution.png" alt="icons" />
                    <Typography variant="h3" color="inherit">
                     Vesting and <br/>  Distribution
                    </Typography>
                    <Typography color="inherit" variant="body2">
                    The first launchpad to allow holders to grow with the network, regardless of participation.
                    </Typography>
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Box>
          <div className="lines">
            <div className="line"></div>
            <div className="line"></div>
            <div className="line"></div>
          </div>
        </Container>
      </Box>
      <Pools />
    </>
  )
}
