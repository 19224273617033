import React, { useState } from 'react'
import {
  Typography,
  Box,
  makeStyles,
  Button,
  LinearProgress,
  withStyles,
  Avatar,
  Grid,
  Link,
} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { CallMissedSharp } from '@material-ui/icons'
import moment from 'moment'
import { ethers } from 'ethers'
import { sortAddress } from '../utils'
import ButtonCircularProgress from './ButtonCircularProgress'
// import { sortAddress } from "src/constant";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
    color: '#000',
    fontWeight: '599',
  },
}))(TableCell)
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow)

const useStyles = makeStyles({
  abc: {
    color: '#ccc',
    backgroundColor: '#000',
    fontSize: '15px',
    fontWeight: '400',
  },
  mt: {
    marginTop: '50px',
  },
})
export default function IdoDetail(props) {
  const {
    poolDataList,
    poolMoreDataa,
    poolExtraDataa,
    maxInvest,
    minInvest,
    isupdating,
    // maxUserInvest,
    myViewData,
    tokenName,
  } = props
  const classes = useStyles()
  const [tokenforSale, setTokenForSale] = useState(poolDataList[5]?.toString())
  const [endTime, setEndTime] = useState(poolDataList[2]?.toString())
  const [timeLeft, setTimeLeft] = useState(poolDataList[1]?.toString())
  const [startTime, setStartTime] = useState(poolMoreDataa[2]?.toString())
  const [addressToken, setAddress] = useState(poolDataList[0]?.toString())
  const [yourEThInvestment, setyourEthINvestment] = useState(
    myViewData[2]?.toString(),
  )
  const [data, setData] = useState(poolMoreDataa[1]?.toString())
  return (
    <Box className="width100">
      <Grid container spacing={5}>
        {isupdating ? (
          <Box p={1} display="flex" justifyContent="center" alignItems="center">
            <h5 style={{ color: '#000' }}>Loading...</h5>{' '}
            <ButtonCircularProgress />
          </Box>
        ) : (
          <>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        component="td"
                        className={classes.abc}
                        align="left"
                        colSpan={2}
                      >
                        Subscription Info{' '}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Total Token for Sales
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" align="right">
                        {tokenforSale
                          ? ethers.utils.formatEther(tokenforSale)
                          : '0'}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Start time
                      </StyledTableCell>

                      <StyledTableCell component="td" scope="row" align="right">
                        {startTime
                          ? moment(new Date(parseInt(startTime) * 1000)).format(
                              'YYYY-MM-DD HH:mm',
                            )
                          : '0/0/00  00:00'}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        End time
                      </StyledTableCell>

                      <StyledTableCell component="td" scope="row" align="right">
                        {endTime
                          ? moment(new Date(parseInt(endTime) * 1000)).format(
                              'YYYY-MM-DD HH:mm',
                            )
                          : '0/0/00 00:00'}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Hard Cap
                      </StyledTableCell>

                      <StyledTableCell component="td" scope="row" align="right">
                        {tokenforSale
                          ? ethers.utils.formatEther(tokenforSale)
                          : '0'}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Min Invest
                      </StyledTableCell>

                      <StyledTableCell component="td" scope="row" align="right">
                        {minInvest ? ethers.utils.formatEther(minInvest) : '0'}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Max Invest
                      </StyledTableCell>

                      <StyledTableCell component="td" scope="row" align="right">
                        {maxInvest
                          ? ethers.utils.formatEther(maxInvest.toString())
                          : '0'}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        component="td"
                        className={classes.abc}
                        align="left"
                        colSpan={2}
                      >
                        Token Information{' '}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Name
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" align="right">
                        {tokenName ? tokenName : '--'}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Address
                      </StyledTableCell>
                      {addressToken && (
                        <StyledTableCell
                          component="td"
                          scope="row"
                          align="right"
                        >
                          {addressToken ? sortAddress(addressToken) : '0'}
                        </StyledTableCell>
                      )}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Total Tokens sold
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" align="right">
                        {tokenforSale
                          ? Number(
                              ethers.utils.formatEther(tokenforSale) -
                                ethers.utils.formatEther(poolMoreDataa[1]),
                            ).toFixed(4)
                          : '0'}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <TableContainer component={Paper} className={classes.mt}>
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell
                        component="td"
                        className={classes.abc}
                        align="left"
                        colSpan={2}
                      >
                        Your Details
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Your ETH Investment
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" align="right">
                        {yourEThInvestment
                          ? ethers.utils.formatEther(yourEThInvestment)
                          : '0'}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell component="td" scope="row">
                        Your Tokens
                      </StyledTableCell>
                      <StyledTableCell component="td" scope="row" align="right">
                        {myViewData[3]
                          ? ethers.utils.formatEther(myViewData[3].toString())
                          : '0'}
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer> */}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}
