import PlatformAbi from './PlatformAbi.json'
import Erc20TokenAbi1 from './erc20TokenAbi.json'
export default PlatformAbi

export const Erc20TokenAbi = Erc20TokenAbi1

export const AvalancheAddress = '0xF4451197084482e4732Edf84a6F96cC17Ed8C375'
export const USDTAvalanche = '0xfAe1D71C827fd59E9B62744A5528e45d23d99f6c'
// export const AvalancheAddress = "0x73E5c6f6236C5746dc1B6d98089aB576e590b17A";
// export const ERC20tokenAddress = "0x352E9437E5403fc580FE6dA999289D4696bF9b7a";
export const ERC20tokenAddress = '0x81eF21eA64aE9D9fdc26041de49cbacb20f9E6eb'

export const PlatformContractAddress =
  '0x01937c2BAa3A8F07A484626DDF66cd41d23EfA19'
export const vestingAddress = '0x2bD2074d2Fe72f00eBD3837641771F64e850AA07'
export const ethAddress = '0x0000000000000000000000000000000000000000'
