import React, { createContext, useEffect, useState } from 'react'
import { injected, SUPPORTED_WALLETS } from '../connectors'
import { useWeb3React } from '@web3-react/core'
import {
  ACTIVE_NETWORK,
  Finalcontractaddress,
  NetworkDetails,
} from '../constants/'
import ThetaPunkABI from '../abis/ThetaPunkABI.json'
import axios from 'axios'
import { getWeb3ContractObject, getWeb3Obj, getContract } from '../utils'
import { toast } from 'react-toastify'
import PlatformAbi, { AvalancheAddress, Erc20TokenAbi } from '../context/abi'
import { RPC_URL } from '../constants'
import useIsMountedRef from '../components/useIsMountedRef'
export const UserContext = createContext()

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem('userAddress', userAddress)
  } else {
    sessionStorage.removeItem('userAddress')
  }
}

export default function AuthProvider(props) {
  const { activate, active, account, library, chainId } = useWeb3React()
  const [nftList, setNftList] = useState([])
  const [userTotalNFTS, setUserTotalNFTS] = useState(0)
  const [totalSupply, setTotalSupply] = useState(0)
  const [maxSupply, setMaxSupply] = useState(0)
  const [nftprice, setNftprice] = React.useState()
  const [isSaleActive, setIsSaleActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [poolData, setAllPoolData] = useState([])
  const [isUpdating, setIsUpdatingData] = useState(false)
  const allPoolRef = useIsMountedRef()
  console.log('ACTIVE_NETWORK.toString(16)', ACTIVE_NETWORK.toString(16))
  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler()
        }
      }
    }
  }, [chainId, account])

  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x' + ACTIVE_NETWORK.toString(16) }],
      })
    } catch (error) {
      console.log('ERROR', error)
      toast.warn(error.message)
      if (error.code === 4902) {
        addNetworkHandler()
      }
    }
  }
  const getTableDataDashboard = async () => {
    setIsUpdatingData(true)
    try {
      console.log('underLogic')
      const contract = await getWeb3ContractObject(
        PlatformAbi,
        AvalancheAddress,
        RPC_URL,
      )
      console.log('contract----', contract)
      setAllPoolData([])
      setIsLoading(true)
      let res = await contract.methods.poolsCount().call()

      let resultObj = {}
      for (let i = res - 1; i >= 0; i--) {
        /*jshint loopfunc: true */
        let poolBaseData = await contract.methods.GetPoolBaseData(i).call()
        let poolMoreData = await contract.methods.GetPoolMoreData(i).call()
        // let poolExtraData = await contract.methods.GetPoolExtraData(i).call();
        let poolStatus = await contract.methods.GetPoolStatus(i).call()

        resultObj = {
          Token: poolBaseData[0],
          Creator: poolBaseData[1],
          FinishTime: poolBaseData[2],
          Rate: poolBaseData[3],
          StartAmount: poolBaseData[5],
          Lefttokens: poolMoreData[1],
          // Maincoin: poolExtraData[1],
          StartTime: poolMoreData[2],
        }
        const tokenNameContract = await getWeb3ContractObject(
          Erc20TokenAbi,
          poolBaseData[0],
          RPC_URL,
        )

        let tokenNameFun = await tokenNameContract.methods.name().call()
        if (allPoolRef.current) {
          setAllPoolData((data) => [
            ...data,
            {
              id: i,
              data: resultObj,
              poolStatus: poolStatus,
              tokenName: tokenNameFun,
            },
          ])
        }
      }
      setIsUpdatingData(false)

      setIsLoading(false)
    } catch (error) {
      console.log('ERRROR', error)
      setIsUpdatingData(false)
      setIsLoading(false)
    }
  }
  console.log('poolsList------', poolData)
  useEffect(() => {
    getTableDataDashboard()
  }, [allPoolRef])

  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: NetworkDetails,
      })
    } catch (error) {
      console.log('ERROR', error)
      toast.warn(error.message)
    }
  }

  const connectWalletWeb3 = () => {
    // const connector = data.connector;

    // if (connector && connector.walletConnectProvider?.wc?.uri) {
    //   connector.walletConnectProvider = undefined;
    // }

    activate(injected, undefined, true).catch((error) => {
      if (error) {
        activate(injected)
      }
    })
  }
  let data = {
    nftList,
    userTotalNFTS,
    maxSupply,
    totalSupply,
    isSaleActive,
    nftprice,
    poolData,
    isLoading,
    isUpdating,
    getGalleryData: () => Gallery(),
    getMoreData: () => getMoreData(),
    updateUser: (account) => {
      setSession(account)
    },
    connectWallet: (data) => connectWalletWeb3(data),

    getDashboardPools: () => getTableDataDashboard(),
  }

  const Gallery = async () => {
    setNftList([])
    const contract = getContract(
      Finalcontractaddress,
      ThetaPunkABI,
      library,
      account,
    )
    const txaCount = await contract.balanceOf(account)
    const txa = txaCount.toString()
    setUserTotalNFTS(txa)

    try {
      for (let i = 0; i < txa; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i)
        const filter = await contract.tokenURI(id.toString())
        const res = await axios.get(filter)
        if (res.status === 200) {
          setNftList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ])
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
  React.useEffect(() => {
    if (active === true && account && chainId === ACTIVE_NETWORK) {
      Gallery()
      getMoreData()
    }
  }, [account, active, chainId])

  React.useEffect(() => {
    getMoreData()
  }, [account])

  const getMoreData = async () => {
    try {
      const web3 = await getWeb3Obj()
      const contract = await getWeb3ContractObject(
        ThetaPunkABI,
        Finalcontractaddress,
      )
      const totalSupp = await contract.methods.totalSupply().call()
      console.log('totalSupp', totalSupp)
      setTotalSupply(parseFloat(totalSupp.toString()))
      const MAX_PUNKS = await contract.methods.MAX_PUNKS_SUPPLY().call()
      setMaxSupply(parseFloat(MAX_PUNKS.toString()))
      console.log('MAX_PUNKS', MAX_PUNKS)
      const saleIsactive = await contract.methods.saleIsActive().call()
      console.log('saleIsactive', saleIsactive)
      const NFTP = await contract.methods.getPUNKPrice().call()
      console.log('saleIsactive', saleIsactive)
      const etherValue = await web3.utils.fromWei(NFTP.toString())
      setNftprice(parseFloat(etherValue))
      setIsSaleActive(saleIsactive)
    } catch (error) {
      console.log('ERROR', error)
    }
  }

  // useEffect(() => {
  //   const userAddress = window.sessionStorage.getItem("userAddress");
  //   if (userAddress) {
  //     data.connectWallet();
  //   }
  // }, []); //eslint-disable-line

  useEffect(() => {
    if (window.sessionStorage.getItem('walletName')) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem('walletName'),
      )
      connectWalletWeb3(selectectWalletDetails[0].data)
    }
  }, [])

  useEffect(() => {
    data.updateUser(account)
  }, [account]) //eslint-disable-line

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  )
}
